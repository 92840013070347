import {
  REQUEST_GET_STYLES,
  GET_STYLES_SUCCESS,
  GET_STYLES_FAILURE,
} from './actionTypes';

const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {

    case REQUEST_GET_STYLES:
      return Object.assign({}, state, { isFetching: true });
    case GET_STYLES_SUCCESS:
      const styleMap = {};
      action.response.forEach(s => {
        styleMap[s.name] = styleMap[s.name] || Object.assign({}, s, { ids: [] });
        styleMap[s.name].ids.push(s.id);
      });
      const finalStyles = Object.keys(styleMap).map(k => styleMap[k]);
      return Object.assign({}, state, {
        data: finalStyles,
        isFetching: false,
        //count: null,
       });
    case GET_STYLES_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });
    default:
      return state;
  }
}

export default reducer;
