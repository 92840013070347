//GET /ShoppingLists
export const REQUEST_GET_LISTS = 'REQUEST_GET_LISTS';
export const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
export const GET_LISTS_FAILURE = 'GET_LISTS_FAILURE';

//GET /ShoppingLists
export const REQUEST_GET_LISTS_FOR_ACTIONS = 'REQUEST_GET_LISTS_FOR_ACTIONS';
export const GET_LISTS_FOR_ACTIONS_SUCCESS = 'GET_LISTS_FOR_ACTIONS_SUCCESS';
export const GET_LISTS_FOR_ACTIONS_FAILURE = 'GET_LISTS_FOR_ACTIONS_FAILURE';

//GET /ShoppingLists/{id}
export const REQUEST_GET_LIST = 'REQUEST_GET_LIST';
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const GET_LIST_FAILURE = 'GET_LIST_FAILURE';

//POST /ShoppingLists
export const REQUEST_CREATE_LIST = 'REQUEST_CREATE_LIST';
export const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS';
export const CREATE_LIST_FAILURE = 'CREATE_LIST_FAILURE';

//DELETE /ShoppingLists/{id}
export const REQUEST_DELETE_LIST = 'REQUEST_DELETE_LIST';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const DELETE_LIST_FAILURE = 'DELETE_LIST_FAILURE';

//PATCH /ShoppingLists/{id}
export const REQUEST_UPDATE_LIST = 'REQUEST_UPDATE_LIST';
export const UPDATE_LIST_SUCCESS = 'UPDATE_LIST_SUCCESS';
export const UPDATE_LIST_FAILURE = 'UPDATE_LIST_FAILURE';


//GET /ShoppingLists/{id}/items
export const REQUEST_GET_LIST_ITEMS = 'REQUEST_GET_LIST_ITEMS';
export const GET_LIST_ITEMS_SUCCESS = 'GET_LIST_ITEMS_SUCCESS';
export const GET_LIST_ITEMS_FAILURE = 'GET_LIST_ITEMS_FAILURE';

//GET /ShoppingLists/{id}/items/{itemId}
export const REQUEST_GET_LIST_ITEM = 'REQUEST_GET_LIST_ITEM';
export const GET_LIST_ITEM_SUCCESS = 'GET_LIST_ITEM_SUCCESS';
export const GET_LIST_ITEM_FAILURE = 'GET_LIST_ITEM_FAILURE';

export const REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM';

//POST /ShoppingLists/{id}/items
export const REQUEST_CREATE_LIST_ITEM = 'REQUEST_CREATE_LIST_ITEM';
export const CREATE_LIST_ITEM_SUCCESS = 'CREATE_LIST_ITEM_SUCCESS';
export const CREATE_LIST_ITEM_FAILURE = 'CREATE_LIST_ITEM_FAILURE';

//DELETE /ShoppingLists/{id}/items/{id}
export const REQUEST_DELETE_LIST_ITEM = 'REQUEST_DELETE_LIST_ITEM';
export const DELETE_LIST_ITEM_SUCCESS = 'DELETE_LIST_ITEM_SUCCESS';
export const DELETE_LIST_ITEM_FAILURE = 'DELETE_LIST_ITEM_FAILURE';

//PATCH /ShoppingLists/{id}/items/{id}
export const REQUEST_UPDATE_LIST_ITEM = 'REQUEST_UPDATE_LIST_ITEM';
export const UPDATE_LIST_ITEM_SUCCESS = 'UPDATE_LIST_ITEM_SUCCESS';
export const UPDATE_LIST_ITEM_FAILURE = 'UPDATE_LIST_ITEM_FAILURE';

export const SELECT_LIST_SUCCESS = 'SELECT_LIST_SUCCESS';
export const SELECT_LIST_ITEM_SUCCESS = 'SELECT_LIST_ITEM_SUCCESS';

export const SET_LIST_DATA = 'SET_LIST_DATA';
