exports.CATALOG_DEFAULT_COLUMN = {
  colName: '',
  display: '',
  important: false,
  sortable: false,
  description: '',
  wrap: false,
  required: false,
  type: 'string',
  searchable: false,
  hideColumn: false,
  hideForm: false,
  numColumns: 2,
  postfix: '',
  inputType: null,
  inputHelperText: '',
  columnSpanAdjust: 0,
  header: null,
  customValueDisplay: null,
  customDisplay: null,
  inline: true,
  hideView: false,
};

//select options
exports.STYLE_TYPE_OPTIONS = ['Ale', 'Lager', 'Mixed', 'Mead', 'Cider', 'Wheat', 'Wine'];
exports.STYLE_LETTER_OPTIONS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
exports.EQUIPMENT_TYPE_OPTIONS = ['General', 'Extract', 'Partial Mash', 'All Grain'];
exports.MASH_STEP_TYPE_OPTIONS = ['Infusion', 'Decoction', 'Temperature'];
exports.CARBONATION_TYPE_OPTIONS = ['Bottle', 'Keg'];
exports.FERMENTATION_TYPE_OPTIONS = ['Single Stage', 'Two Stage', 'Three Stage', 'Four Stage'];
exports.HOP_TYPE_OPTIONS = ['Bittering', 'Aroma', 'Both'];
exports.HOP_FORM_OPTIONS = ['Pellet', 'Plug', 'Leaf', 'Hop Extract (CO2)', 'Extract (Isomerized)'];
exports.HOP_USE_OPTIONS = ['Boil', 'Dry Hop', 'Mash', 'First Wort', 'Steep/Whirlpool'];
exports.HOP_FORM_LIMITED_OPTIONS = ['Pellet', 'Plug', 'Leaf'];
exports.FERMENTABLE_TYPE_OPTIONS = ['Grain', 'Extract', 'Sugar', 'Adjunct', 'Dry Extract', 'Fruit', 'Juice', 'Honey'];
exports.FERMENTABLE_USE_OPTIONS = ['Mash', 'Steep', 'Boil', 'Whirlpool', 'Primary', 'Secondary', 'Tertiary', 'Bottling'];
exports.YEAST_TYPE_OPTIONS = ['Ale', 'Lager', 'Wine', 'Champagne', 'Wheat'];
exports.YEAST_FORM_OPTIONS = ['Liquid', 'Dry', 'Slant', 'Culture'];
exports.YEAST_FLOCCULATION_OPTIONS = ['Low', 'Medium', 'High', 'Very High'];
exports.STARTER_TOOL_BATCH_TYPES = ['Ale', 'Lager', 'Hybrid, Wheat'];
exports.STARTER_TOOL_YEAST_TYPE = ['Liquid', 'Dry', 'Slurry'];
exports.MISC_INGREDIENTS_TYPE_OPTIONS = ['Spice', 'Fining', 'Herbs', 'Flavor', 'Other', 'Water Agent'];
exports.MISC_INGREDIENTS_USE_OPTIONS = ['Boil', 'Mash', 'Primary', 'Secondary', 'Bottling', 'Sparge'];
exports.RECIPE_TYPE_OPTIONS = ['Extract', 'Partial Mash', 'All Grain'];
exports.BREWING_TYPE_OPTIONS = ['All Grain', 'Brew in a Bag', 'Partial Mash', 'Extract'];
exports.CURRENCY_DECIMAL_POINTS = ['.', ','];
exports.ACID_OPTIONS = ['None', 'Lactic Acid', 'Phosphoric Acid', 'Acid Malt'];
exports.DATE_FORMATS = ['MM/DD/YYYY', 'MM-DD-YYYYY', 'YYYY/MM/DD', 'YYYY-MM-DD'];
exports.BITTERNESS_FORMULA_OPTIONS = ['Tinseth', 'Rager', 'Garetz'];
exports.UNIT_OPTIONS = ['mg', 'g', 'oz', 'lb', 'kg', 'ml', 'tsp', 'Tbs', 'cup', 'pnt', 'qt', 'l', 'gal', 'Items'];
exports.DILUTE_WITH = ['Wort/Beer', 'Water'];
exports.INFUSION_TYPE = ['Inital Strike', 'Infusion Step'];
exports.BILLING_TYPE_OPTIONS = ['stripe', 'ios', 'android'];
exports.AGITATION_METHODS = ['None', 'Shaking', 'Stir Plate'];
exports.YEAST_PITCH_RATE = [
  ['MFG Recommended 0.35 (Ale, fresh yeast only)', 0.35],
  ['MFG Recommended+ 0.5 (Ale, fresh yeast only)', 0.5],
  ['Pro Brewer 0.75 (Ale)', 0.75],
  ['Pro Brewer 1.0 (Ale, or High Gravity Ale)', 1],
  ['Pro Brewer 1.25 (Ale, or High Gravity Ale)', 1.25],
  ['Pro Brewer 1.5 (Lager)', 1.5],
  ['Pro Brewer 1.75 (Lager)', 1.75],
  ['Pro Brewer 2.0 (High Gravity Lager)', 2],
];

exports.SALT_MAP = {
  'Baking Soda': 'bakingSoda',
  'Calcium Chloride': 'calciumChloride',
  'Epsom Salt (MgSO4)': 'epsomSalt',
  'Gypsum (Calcium Sulfate)': 'gypsum',
  'Gypsum': 'gypsum',
  'Salt': 'canningSalt',
  'Calcium Hydroxide (Slaked Lime)': 'calciumHydroxide',
};

exports.ACID_TYPES = [
  {
    name: "Lactic Acid (88%)",
    normality: 11.811,
    anionPerMeq: 89
  },
  {
    name: "Hydrochloric Acid (10%)",
    normality: 2.875,
    anionPerMeq: 35.4
  },
  {
    name: "Hydrochloric Acid (37%)",
    normality: 11.978,
    anionPerMeq: 35.4
  },
  {
    name: "Phosphoric Acid (10%)",
    normality: 1.071,
    anionPerMeq: 96
  },
  {
    name: "Phosphoric Acid (75%)",
    normality: 12.326,
    anionPerMeq: 96
  },
  {
    name: "Sulfuric Acid (10%)",
    normality: 2.182,
    anionPerMeq: 48
  }
];

exports.FERMENTATION_STEPS = [{
  display: 'Waiting for Brew Day',
  dayCountKey: 'waitingBrewDays',
},{
  display: 'Brew Day',
  dayCountKey: 'brewDays',
}, {
  display: 'Primary Fermentation',
  dayCountKey: 'primDays',
}, {
  display: 'Secondary Fermentation',
  dayCountKey: 'secDays',
}, {
  display: 'Tertiary Fermentation',
  dayCountKey: 'tertDays',
}, {
  display: 'Bulk Aging',
  dayCountKey: 'bulkDays',
}, {
  display: 'Conditioning',
  dayCountKey: 'age',
}, {
  display: 'Drinking',
  dayCountKey: 'drinking',
}];

//units
exports.TIME_UNITS = ['mins', 'hours', 'days', 'weeks'];
exports.WEIGHT_UNITS = ['lb', 'oz', 'kg', 'g', 'mg'];
exports.VOLUME_UNITS = ['gal', 'fl-oz', 'qt', 'l', 'cup', 'pnt', 'ml','tsp', 'Tbs'];
exports.REFRACTOMETER_UNITS = ['Brix', 'RI', 'SG'];
exports.COLOR_UNITS = ['SRM', 'EBC'];
exports.GRAVITY_UNITS = ['SG', 'Plato', 'Brix'];
exports.PRESSURE_UNITS = ['psi', 'kPa', 'bar'];
exports.TEMPERATURE_UNITS = ['F', 'C', 'K'];
exports.ELEVATION_UNITS = ['ft', 'm'];
exports.FALLBACK_UNIT = 'Items';

//These are mapping to specific columns of what should be stored in the database
exports.UNIT_DEFAULTS_MAP = {
  batchVol: 'fl-oz',
  weight: 'oz',
  hop: 'oz',
  yeastStarter: 'l',
  yeast: 'ml',
  mashVol: 'qt',
  temperature: 'F',
  pressure: 'psi',
  gravity: 'SG',
  color: 'SRM',
  refractometer: 'Brix',
  elevation: 'ft',
  salts: 'g',
  co2: 'vols',
};

exports.UNIT_DISPLAY_MAP = {
  'fl-oz': 'fl oz',
  'l': 'L',
};

exports.MODEL_MAP = {
  recipe: {
    display: 'recipe',
    displayPlural: 'recipes',
    model: 'recipe',
    apiBasePath: 'recipe',
    apiRelationPath: 'recipe',
    idKey: 'recipeId',
    iconClass: 'fas fa-clipboard-list',
    unitKey: null,
    beerXmlKey: 'recipe',
  },
  hop: {
    display: 'hop',
    displayPlural: 'hops',
    model: 'hop',
    apiBasePath: 'hop',
    apiRelationPath: 'hop',
    idKey: 'hopId',
    iconClass: 'brew-icon-hop',
    unitKey: 'hopUnits',
    beerXmlKey: 'hop',
    recipePropertyName: 'hops',
  },
  carbonation: {
    display: 'carbonation',
    displayPlural: 'carbonations',
    model: 'carbonation',
    apiBasePath: 'carbonation',
    apiRelationPath: 'carbonation',
    idKey: 'carbonationId',
    iconClass: 'fas fa-cloud',
    recipePropertyName: 'carbonation',
  },
  customItem: {
    display: 'custom item',
    displayPlural: 'custom items',
    model: 'customItem',
    apiBasePath: 'customItem',
    apiRelationPath: 'customItem',
    idKey: 'customItemId',
    iconClass: 'fas fa-shapes',
    recipePropertyName: 'customItems',
  },
  equipment: {
    display: 'equipment',
    displayPlural: 'equipment',
    model: 'equipment',
    apiBasePath: 'equipment',
    apiRelationPath: 'equipment',
    idKey: 'equipmentId',
    iconClass: 'brew-icon-brew_pot',
    beerXmlKey: 'equipment',
    recipePropertyName: 'equipment',
  },
  fermentable: {
    display: 'fermentable',
    displayPlural: 'fermentables',
    model: 'fermentable',
    apiBasePath: 'fermentable',
    apiRelationPath: 'fermentable',
    idKey: 'fermentableId',
    iconClass: 'brew-icon-grain',
    unitKey: 'weightUnits',
    beerXmlKey: 'fermentable',
    recipePropertyName: 'fermentables',
  },
  fermentation: {
    display: 'fermentation',
    displayPlural: 'fermentations',
    model: 'fermentation',
    apiBasePath: 'fermentation',
    apiRelationPath: 'fermentation',
    idKey: 'fermentationId',
    iconClass: 'brew-icon-airlock',
    recipePropertyName: 'fermentation',
  },
  mash: {
    display: 'mash type',
    displayPlural: 'mash types',
    model: 'mash',
    apiBasePath: 'mash',
    apiRelationPath: 'mash',
    idKey: 'mashId',
    iconClass: 'brew-icon-mash_fork',
    beerXmlKey: 'mash',
    recipePropertyName: 'mash',
  },
  miscIngredient: {
    display: 'misc. ingredient',
    displayPlural: 'misc. ingredients',
    model: 'miscIngredient',
    apiBasePath: 'miscIngredient',
    apiRelationPath: 'miscIngredient',
    idKey: 'miscIngredientId',
    iconClass: 'fas fa-shapes',
    beerXmlKey: 'misc',
    recipePropertyName: 'miscIngredients',
  },
  style: {
    display: 'style',
    displayPlural: 'styles',
    model: 'style',
    apiBasePath: 'style',
    apiRelationPath: 'style',
    idKey: 'styleId',
    iconClass: 'fas fa-shapes',
    beerXmlKey: 'style',
    recipePropertyName: 'style',
  },
  water: {
    display: 'water',
    displayPlural: 'water',
    model: 'water',
    apiBasePath: 'water',
    apiRelationPath: 'water',
    idKey: 'waterId',
    iconClass: 'fas fa-tint',
    unitKey: 'batchVolUnits',
    beerXmlKey: 'water',
    recipePropertyName: 'waters',
  },
  yeast: {
    display: 'yeast',
    displayPlural: 'yeasts',
    model: 'yeast',
    apiBasePath: 'yeast',
    apiRelationPath: 'yeast',
    idKey: 'yeastId',
    iconClass: 'brew-icon-yeast',
    unitKey: 'yeastUnits',
    beerXmlKey: 'yeast',
    recipePropertyName: 'yeasts',
  },
  shoppingList: {
    display: 'shopping list',
    displayPlural: 'shopping lists',
    model: 'shoppingList',
    apiBasePath: 'shoppingList',
    apiRelationPath: 'shoppingList',
    idKey: 'shoppingListId',
    iconClass: 'fas fa-shopping-basket',
  },
  inventory: {
    display: 'inventory',
    displayPlural: 'inventories',
    model: 'inventory',
    apiBasePath: 'inventory',
    apiRelationPath: 'inventory',
    idKey: 'inventoryId',
    iconClass: 'fas fa-check-square-o',
  },
}
exports.LIST_MODELS = ['hop', 'customItem', 'miscIngredient', 'fermentable', 'yeast', 'water'];
exports.LIST_MODELS_PLURAL = ['hops', 'fermentables', 'waters', 'yeasts', 'customItems', 'miscIngredients'];
exports.PROFILE_MODELS = ['style', 'fermentation', 'equipment', 'mash', 'carbonation'];


//not sure what this is for
exports.UNITS = ['mg', 'g', 'oz', 'lb', 'kg', 'ml', 'tsp', 'tbsp', 'Cup', 'pt', 'qt', 'L', 'gal', 'items'];

//SRM colors
exports.SRM_HEX_MAP = {
  1: 'FFE699',
  2: 'FFD878',
  3: 'FFCA5A',
  4: 'FFBF42',
  5: 'FBB123',
  6: 'F8A600',
  7: 'F39C00',
  8: 'EA8F00',
  9: 'E58500',
  10: 'DE7C00',
  11: 'D77200',
  12: 'CF6900',
  13: 'CB6200',
  14: 'C35900',
  15: 'BB5100',
  16: 'B54C00',
  17: 'B04500',
  18: 'A63E00',
  19: 'A13700',
  20: '9B3200',
  21: '952D00',
  22: '8E2900',
  23: '882300',
  24: '821E00',
  25: '7B1A00',
  26: '771900',
  27: '701400',
  28: '6A0E00',
  29: '660D00',
  30: '5E0B00',
  31: '5A0A02',
  32: '600903',
  33: '520907',
  34: '4C0505',
  35: '470606',
  36: '440607',
  37: '3F0708',
  38: '3B0607',
  39: '3A070B',
  40: '36080A',
};


//extract, all grain, brew in a bag, partial mash
exports.BREWING_STEPS = [
  //prep
  { name: 'Yeast starter' },
  { name: 'Hydrate yeast' },
  { name: 'Clean and Prepare Brewing Equipment' },
  { name: 'Get water needed' },
  //mash or steep
  { name: 'Water prep' },
  { name: 'Steep the grains' },
  { name: 'Heat water' },
  { name: 'Saccharification' },
  { name: 'Mash out' },
  { name: 'Sparging' },
  { name: 'Measure pre boil volume '},
  { name: 'Measure pre boil gravity' },
  //boil steps
  ////kettle sour
  { name: 'Add water to achive boil volume'},
  { name: 'Kettle sour sterilization boil' },
  { name: 'Pitch lactobacillus' },
  { name: 'Souring' },
  { name: 'Taste over the next 24-48 hr to desired sourness' },
  //normal boil
  { name: 'Boil' },
  { name: 'Ingredient additions' },
  { name: 'Cool wort' },
  //fermentation
  { name: 'Transfer wort to fermenter' },
  { name: 'Get gravity reading' },
  { name: 'Measure volume' },
  { name: 'Add water to achive final volume'},
  { name: 'Pitch yeast' },
  { name: 'Primary Fermentation' },
  { name: 'Secondary Fermentaion' },
  { name: 'Third Fermentaion' },
  { name: 'Aging' },
  { name: 'Dry hopping / Additional ingredients' },
  //bottling/kegging
  { name: 'Clean bottling equipment' },
  { name: 'Add bottling sugar for carbonation' },
  { name: 'Kegging/Bottling Ingredients' },
  { name: 'Transfer beer to bottle/keg' },
  { name: 'Bottling' },
  { name: 'Add co2 to keg' },
];

/*
assumptions:
 * first plan is the one assigned after sign up
 * removing trialLength or setting to 0 assumes a user can use it indefinatly
 * count 1000000 === unlimited in the ui
 * activePlan indicates order of smallest to largets plan (less $ -> more $)
*/
exports.ACCOUNT_CONFIG = {
  activePlans: ['standard', 'manual'],
  plans: {
    standard: {
      id: 'standard',
      trialLength: 30,
      yearlyPrice: 96,
      monthlyPrice: 10,
      stripeYearlyPriceIds: {
        development: 'price_1LdarYLTgTRK5CafBwpACbCA',
        production: 'price_1LcZNvLTgTRK5CafjGqCL2J9',
      },
      stripeMonthlyPriceIds: {
        development: 'price_1LcZKBLTgTRK5Caf9b5mabce',
        production: 'price_1LcZNvLTgTRK5CafCT1agGar',
      },
      features: {
        recipe: { count: 1000000 },
        session: { count: 1000000 },
        dataPortability: {},
        team: { count: 10, },
        brewingNotifications: {},
        fermentationMonitoring: {},
        premiumSupport: {},
      },
    },
    manual: {
      id: 'manual',
      stripeYearlyPriceIds: {
        development: 'price_1LdarYLTgTRK5CafBwpACbCA',
        production: 'price_1LcZNvLTgTRK5CafjGqCL2J9',
      },
      stripeMonthlyPriceIds: {
        development: 'price_1LcZKBLTgTRK5Caf9b5mabce',
        production: 'price_1LcZNvLTgTRK5CafCT1agGar',
      },
      features: {
        recipe: { count: 1000000 },
        session: { count: 1000000 },
        dataPortability: {},
        team: { count: 10, },
        brewingNotifications: {},
        fermentationMonitoring: {},
        premiumSupport: {},
      },
    },
  },
};