//GET /Accounts/{id}/sessions
export const REQUEST_GET_SESSIONS = 'REQUEST_GET_SESSIONS';
export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS';
export const GET_SESSIONS_FAILURE = 'GET_SESSIONS_FAILURE';

//GET /Sessions/{id}
export const REQUEST_GET_SESSION = 'REQUEST_GET_SESSION';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE';

//POST /Account/{id}/sessions
export const REQUEST_CREATE_SESSION = 'REQUEST_CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE';

//DELETE /Sessions/{id}
export const REQUEST_DELETE_SESSION = 'REQUEST_DELETE_SESSION';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE';

//PATCH /Sessions/{id}
export const REQUEST_UPDATE_SESSION = 'REQUEST_UPDATE_SESSION';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export const UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE';

//POST /Sessions/{id}/sessionReading
export const REQUEST_CREATE_READING = 'REQUEST_CREATE_READING';
export const CREATE_READING_SUCCESS = 'CREATE_READING_SUCCESS';
export const CREATE_READING_FAILURE = 'CREATE_READING_FAILURE';

//DELETE /Sessions/{id}/sessionReading/{id}
export const REQUEST_DELETE_READING = 'REQUEST_DELETE_READING';
export const DELETE_READING_SUCCESS = 'DELETE_READING_SUCCESS';
export const DELETE_READING_FAILURE = 'DELETE_READING_FAILURE';

//PATCH /Sessions/{sessionId}/recipes/{recipeId}/copy
export const REQUEST_ATTACH_RECIPE = 'REQUEST_ATTACH_RECIPE';
export const ATTACH_RECIPE_SUCCESS = 'ATTACH_RECIPE_SUCCESS';
export const ATTACH_RECIPE_FAILURE = 'ATTACH_RECIPE_FAILURE';

//GET /Account/{accountId}/active_sessions
export const REQUEST_ACTIVE_SESSIONS = 'REQUEST_ACTIVE_SESSIONS';
export const ACTIVE_SESSIONS_SUCCESS = 'ACTIVE_SESSIONS_SUCCESS';
export const ACTIVE_SESSIONS_FAILURE = 'ACTIVE_SESSIONS_FAILURE';

export const REMOVE_SESSION_DATA = 'REMOVE_SESSION_DATA';
