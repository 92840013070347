import { ACCOUNT_CONFIG } from 'brewui-common/constants';
import * as developmentConfig from './config.development';
import * as productionConfig from './config.production';

export default function getConfig(){
  var base = {
    levels: ACCOUNT_CONFIG.plans,
    dateFormat: 'MM/dd/yyyy',
    dateTimeFormat: 'MM/dd/yyyy h:mma',
    longDateFormat: 'MMMM do, yyyy',
    shortISODateFormat: 'yyyy-MM-dd',
    GOOGLE_ANALYTICS_ID: 'UA-136518417-1',
  };

  switch(process.env.REACT_APP_BUILD_ENV){
    case 'production':
      return Object.assign({}, base, productionConfig);
    default:
      return Object.assign({}, base, developmentConfig);
  }
};
