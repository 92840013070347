//GET /Recipes
export const REQUEST_GET_RECIPES = 'REQUEST_GET_RECIPES';
export const GET_RECIPES_SUCCESS = 'GET_RECIPES_SUCCESS';
export const GET_RECIPES_FAILURE = 'GET_RECIPES_FAILURE';

//GET /Recipes/{id}
export const REQUEST_GET_RECIPE = 'REQUEST_GET_RECIPE';
export const GET_RECIPE_SUCCESS = 'GET_RECIPE_SUCCESS';
export const GET_RECIPE_FAILURE = 'GET_RECIPE_FAILURE';

//POST /Recipes
export const REQUEST_CREATE_RECIPE = 'REQUEST_CREATE_RECIPE';
export const CREATE_RECIPE_SUCCESS = 'CREATE_RECIPE_SUCCESS';
export const CREATE_RECIPE_FAILURE = 'CREATE_RECIPE_FAILURE';

//DELETE /Recipes/{id}
export const REQUEST_DELETE_RECIPE = 'REQUEST_DELETE_RECIPE';
export const DELETE_RECIPE_SUCCESS = 'DELETE_RECIPE_SUCCESS';
export const DELETE_RECIPE_FAILURE = 'DELETE_RECIPE_FAILURE';

//PATCH /Recipes/{id}
export const REQUEST_UPDATE_RECIPE = 'REQUEST_UPDATE_RECIPE';
export const UPDATE_RECIPE_SUCCESS = 'UPDATE_RECIPE_SUCCESS';
export const UPDATE_RECIPE_FAILURE = 'UPDATE_RECIPE_FAILURE';

//GET /Account/{id}/recipes/count
export const REQUEST_RECIPE_COUNT = 'REQUEST_RECIPE_COUNT';
export const RECIPE_COUNT_SUCCESS = 'RECIPE_COUNT_SUCCESS';
export const RECIPE_COUNT_FAILURE = 'RECIPE_COUNT_FAILURE';
