import { toastr } from 'react-redux-toastr';
import { trackSubscribe } from '../../tracking';
import queryString from 'querystring';

import {
  REQUEST_GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE,
  REQUEST_UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  REQUEST_CLOSE_ACCOUNT,
  CLOSE_ACCOUNT_SUCCESS,
  CLOSE_ACCOUNT_FAILURE,
  REQUEST_UPDATE_LEVEL,
  UPDATE_LEVEL_SUCCESS,
  UPDATE_LEVEL_FAILURE,
  REQUEST_GET_BILLING,
  GET_BILLING_SUCCESS,
  GET_BILLING_FAILURE,
  REQUEST_GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  REQUEST_SUBSCRIBE,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  REQUEST_UPDATE_CARD,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAILURE,
} from './actionTypes';
import config from '../../config'
const { API_BASE } = config();
let actions = {
  //account data actions
  requestGetAccount: () => ({ type: REQUEST_GET_ACCOUNT }),
  getAccount: (accessToken, accountId) => async (dispatch) => {
    dispatch(actions.requestGetAccount());
    var query = queryString.stringify({filter: JSON.stringify({include: ['customer', 'billing']})});
    try {
      const url = `${API_BASE}/account/${accountId}/account/${accountId}?${query}`;
      const headers = { Authorization: `Bearer ${accessToken}` };
      const resp = await fetch(url, { headers });
      if (resp.status >= 400) throw new Error();
      const json = await resp.json();
      dispatch(actions.getAccountSuccess(json));
    } catch (err) {
      dispatch(actions.getAccountFailure(err));
    }
  },
  getAccountSuccess: (response) => ({
    type: GET_ACCOUNT_SUCCESS,
    response,
  }),
  getAccountFailure: (error) => ({
    type: GET_ACCOUNT_FAILURE,
    error,
  }),

  //account data actions
  requestUpdateAccount: () => ({ type: REQUEST_UPDATE_ACCOUNT }),
  updateAccount: (accessToken, accountId, data) => (dispatch) => {
    dispatch(actions.requestUpdateAccount());
    return fetch(`${API_BASE}/account/${accountId}/account/${accountId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your account information has been saved');
      dispatch(actions.updateAccountSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh!', 'There was an error');
      dispatch(actions.updateAccountFailure(err));
    });
  },
  updateAccountSuccess: (response) => ({
    type: UPDATE_ACCOUNT_SUCCESS,
    response,
  }),
  updateAccountFailure: (error) => ({
    type: UPDATE_ACCOUNT_FAILURE,
    error,
  }),

  requestCloseAccount: () => ({ type: REQUEST_CLOSE_ACCOUNT }),
  closeAccount: (accessToken, accountId) => (dispatch) => {
    dispatch(actions.requestCloseAccount());
    return fetch(`${API_BASE}/account/${accountId}/closeAccount`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.info('We will miss you!', 'Your account information has been deleted');
      dispatch(actions.closeAccountSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh!', 'There was an error');
      dispatch(actions.closeAccountFailure(err));
    });
  },
  closeAccountSuccess: (response) => ({
    type: CLOSE_ACCOUNT_SUCCESS,
    response,
  }),
  closeAccountFailure: (error) => ({
    type: CLOSE_ACCOUNT_FAILURE,
    error,
  }),

  requestUpdateLevel: () => ({ type: REQUEST_UPDATE_LEVEL }),
  updateLevel: (accessToken, accountId, data) => (dispatch) => {
    dispatch(actions.requestUpdateLevel());
    return fetch(`${API_BASE}/account/${accountId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your account information has been saved');
      dispatch(actions.updateLevelSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh!', 'There was an error');
      dispatch(actions.updateLevelFailure(err));
    });
  },
  updateLevelSuccess: (response) => ({
    type: UPDATE_LEVEL_SUCCESS,
    response,
  }),
  updateLevelFailure: (error) => ({
    type: UPDATE_LEVEL_FAILURE,
    error,
  }),

  requestGetBilling: () => ({ type: REQUEST_GET_BILLING }),
  getBilling: (accessToken, accountId) => (dispatch) => {
    dispatch(actions.requestGetBilling());
    return fetch(`${API_BASE}/account/${accountId}/account/${accountId}/billing`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getBillingSuccess(json));
    }).catch(err => {
      dispatch(actions.getBillingFailure(err));
    });
  },
  getBillingSuccess: (response) => ({
    type: GET_BILLING_SUCCESS,
    response,
  }),
  getBillingFailure: (error) => ({
    type: GET_BILLING_FAILURE,
    error,
  }),

  requestGetSubscription: () => ({ type: REQUEST_GET_SUBSCRIPTION }),
  getSubscription: (accessToken, accountId, billingId) => (dispatch) => {
    dispatch(actions.requestGetSubscription());
    return fetch(`${API_BASE}/account/${accountId}/billing/${billingId}/subscription`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getSubscriptionSuccess(json));
    }).catch(err => {
      dispatch(actions.getSubscriptionFailure(err));
    });
  },
  getSubscriptionSuccess: (response) => ({
    type: GET_SUBSCRIPTION_SUCCESS,
    response,
  }),
  getSubscriptionFailure: (error) => ({
    type: GET_SUBSCRIPTION_FAILURE,
    error,
  }),

  requestSubscribe: () => ({ type: REQUEST_SUBSCRIBE }),
  subscribe: (accessToken, payload, level, accountId, interval) => (dispatch) => {
    dispatch(actions.requestSubscribe());
    let body = {
      level: level,
    }

    if (payload) {
      body = Object.assign({}, body, {
        cardBrand: payload.card.brand,
        cardLast4: payload.card.last4,
        countryCode: payload.card.country,
        expireMonth: payload.card.exp_month,
        expireYear: payload.card.exp_year,
        token: payload.id,
        interval,
      });
    }
    return fetch(`${API_BASE}/account/${accountId}/submitPaymentInformation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success', 'Your information has been saved!');
      trackSubscribe(level, interval);
      dispatch(actions.subscribeSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh', 'There was an error. You were not subscribed.', { timeOut: 0 });
      dispatch(actions.subscribeFailure(err));
    });
  },
  subscribeSuccess: (response) => ({
    type: SUBSCRIBE_SUCCESS,
    response,
  }),
  subscribeFailure: (error) => ({
    type: SUBSCRIBE_FAILURE,
    error,
  }),

  requestUpdateCard: () => ({ type: REQUEST_UPDATE_CARD }),
  updateCard: (accessToken, payload, billingId, accountId) => (dispatch) => {
    dispatch(actions.requestUpdateCard());
    return fetch(`${API_BASE}/account/${accountId}/billing/${billingId}/updateCard`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        cardBrand: payload.card.brand,
        cardLast4: payload.card.last4,
        countryCode: payload.card.country,
        expireMonth: payload.card.exp_month,
        expireYear: payload.card.exp_year,
        token: payload.id,
      }),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your credit card information has been saved');
      dispatch(actions.updateCardSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh!', 'There was an error');
      dispatch(actions.updateCardFailure(err));
    });
  },
  updateCardSuccess: (response) => ({
    type: UPDATE_CARD_SUCCESS,
    response,
  }),
  updateCardFailure: (error) => ({
    type: UPDATE_CARD_FAILURE,
    error,
  }),

  requestExtendTrial: () => ({ type: REQUEST_UPDATE_ACCOUNT }),
  extendTrial: (accessToken, accountId) => (dispatch) => {
    dispatch(actions.requestExtendTrial());
    return fetch(`${API_BASE}/account/${accountId}/extendTrial`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      //toastr.success('Success!', 'Your credit card information has been saved');
      dispatch(actions.extendTrialSuccess(json));
    }).catch(err => {
      //toastr.warning('Uh oh!', 'Looks like your trial can't be extended again.');
      dispatch(actions.extendTrialFailure(err));
    });
  },
  extendTrialSuccess: (response) => ({
    type: UPDATE_ACCOUNT_SUCCESS,
    response,
  }),
  extendTrialFailure: (error) => ({
    type: UPDATE_ACCOUNT_FAILURE,
    error,
  }),

};

export default actions;
