import {
  REQUEST_GET_RECIPES,
  GET_RECIPES_SUCCESS,
  GET_RECIPES_FAILURE,
  REQUEST_GET_RECIPE,
  GET_RECIPE_SUCCESS,
  GET_RECIPE_FAILURE,
  REQUEST_CREATE_RECIPE,
  CREATE_RECIPE_SUCCESS,
  CREATE_RECIPE_FAILURE,
  REQUEST_DELETE_RECIPE,
  DELETE_RECIPE_SUCCESS,
  DELETE_RECIPE_FAILURE,
  REQUEST_UPDATE_RECIPE,
  UPDATE_RECIPE_SUCCESS,
  UPDATE_RECIPE_FAILURE,
  REQUEST_RECIPE_COUNT,
  RECIPE_COUNT_SUCCESS,
  RECIPE_COUNT_FAILURE,
} from './actionTypes';
const initialState = {
  isFetching: false,
  isSaved: false,
  data: [],
  selectedRecipe: null,
  count: null,
  error: null,
};



function reducer(state = initialState, action) {
  switch (action.type) {

    case REQUEST_GET_RECIPES:
      return Object.assign({}, state, { isFetching: true });
    case GET_RECIPES_SUCCESS:
      return Object.assign({}, state, {
        data: action.response,
        isFetching: false,
       });
    case GET_RECIPES_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_RECIPE_COUNT:
      return Object.assign({}, state, { isFetching: true });
    case RECIPE_COUNT_SUCCESS:
      return Object.assign({}, state, {
        count: action.response.count,
        isFetching: false,
       });
    case RECIPE_COUNT_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_RECIPE:
      return Object.assign({}, state, { isFetching: true });
    case GET_RECIPE_SUCCESS:
      return Object.assign({}, state, {
        selectedRecipe: action.response,
        isFetching: false,
       });
    case GET_RECIPE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_CREATE_RECIPE:
      return Object.assign({}, state, {
        isFetching: true,
        isSaved: false,
      });
    case CREATE_RECIPE_SUCCESS:
      return Object.assign({}, state, {
        data: [action.response, ...state.data],
        selectedRecipe: action.response,
        isFetching: false,
        isSaved: true,
       });
    case CREATE_RECIPE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
        isSaved: false,
      });

    case REQUEST_DELETE_RECIPE:
      return Object.assign({}, state, { isFetching: true });
    case DELETE_RECIPE_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.filter(recipe => recipe.id !== action.response.id),
        isFetching: false,
        selectedRecipe: null,
      });
    case DELETE_RECIPE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_UPDATE_RECIPE:
      return Object.assign({}, state, { isFetching: true, isSaved: false });
    case UPDATE_RECIPE_SUCCESS:
      const index = state.data.map(d => d.id).indexOf(action.response.id);
      let data = [...state.data];
      data[index] = action.response;
      return Object.assign({}, state, {
        selectedRecipe: action.response,
        data,
        isFetching: false,
        isSaved: true,
      });
    case UPDATE_RECIPE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isSaved: false,
      });

    default:
      return state;
  }
}

export default reducer;
