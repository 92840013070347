export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

//GET /Customers/{id}
export const REQUEST_GET_CUSTOMER = 'REQUEST_GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

//PATCH /Customers/{id}
export const REQUEST_UPDATE_CUSTOMER = 'REQUEST_UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

//POST /Customers/change-password
export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const REQUEST_VERIFICATION_EMAIL = 'REQUEST_VERIFICATION_EMAIL';
export const VERIFICATION_EMAIL_SUCCESS = 'VERIFICATION_EMAIL_SUCCESS';
export const VERIFICATION_EMAIL_FAILURE = 'VERIFICATION_EMAIL_FAILURE';
