import {
  REQUEST_GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAILURE,
  REQUEST_GET_MEMBERSHIPS,
  GET_MEMBERSHIPS_SUCCESS,
  GET_MEMBERSHIPS_FAILURE,
  REQUEST_REMOVE_MEMBER,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FAILURE,
  REQUEST_REMOVE_MEMBERSHIP,
  REMOVE_MEMBERSHIP_SUCCESS,
  REMOVE_MEMBERSHIP_FAILURE,
  REQUEST_GET_INVITES,
  GET_INVITES_SUCCESS,
  GET_INVITES_FAILURE,
  REQUEST_GET_CUSTOMER_INVITES,
  GET_CUSTOMER_INVITES_SUCCESS,
  GET_CUSTOMER_INVITES_FAILURE,
  REQUEST_INVITE_MEMBER,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_FAILURE,
  REQUEST_ACCEPT_INVITE,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  REQUEST_DELETE_INVITE,
  DELETE_INVITE_SUCCESS,
  DELETE_INVITE_FAILURE,
  REQUEST_RESEND_INVITE,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_FAILURE,
  REQUEST_REJECT_INVITE,
  REJECT_INVITE_SUCCESS,
  REJECT_INVITE_FAILURE,
  SELECT_MEMBERSHIP_SUCCESS,
} from './actionTypes';

import { REQUEST_LOGOUT_USER } from '../Auth/actionTypes';

const initialState = {
  isFetching: false,
  error: null,
  members: [],
  invites: [],
  customerInvites: [],
  selectedMembership: null,
  memberships: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LOGOUT_USER:
      return Object.assign({}, initialState);

    case REQUEST_GET_MEMBERS:
      return Object.assign({}, state, { isFetching: true });
    case GET_MEMBERS_SUCCESS:
      return Object.assign({}, state, {
        members: action.response,
        isFetching: false,
       });
    case GET_MEMBERS_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_MEMBERSHIPS:
      return Object.assign({}, state, { isFetching: true });
    case GET_MEMBERSHIPS_SUCCESS:
      return Object.assign({}, state, {
        memberships: action.response,
        isFetching: false,
       });
    case GET_MEMBERSHIPS_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_INVITES:
      return Object.assign({}, state, { isFetching: true });
    case GET_INVITES_SUCCESS:
      return Object.assign({}, state, {
        invites: action.response,
        isFetching: false,
       });
    case GET_INVITES_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_CUSTOMER_INVITES:
      return Object.assign({}, state, { isFetching: true });
    case GET_CUSTOMER_INVITES_SUCCESS:
      return Object.assign({}, state, {
        customerInvites: action.response,
        isFetching: false,
       });
    case GET_CUSTOMER_INVITES_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });


    //members and invites
    case REQUEST_INVITE_MEMBER:
      return Object.assign({}, state, { isFetching: true });
    case INVITE_MEMBER_SUCCESS:
      var invites = [...state.invites, action.response];
      return Object.assign({}, state, {
        invites,
        isFetching: false,
      });
    case INVITE_MEMBER_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_RESEND_INVITE:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RESEND_INVITE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case RESEND_INVITE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_DELETE_INVITE:
      return Object.assign({}, state, { isFetching: true });
    case DELETE_INVITE_SUCCESS:
      return Object.assign({}, state, {
        invites: state.invites.filter(inv => inv.id !== action.response.id),
        isFetching: false,
      });
    case DELETE_INVITE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
      });

    case REQUEST_REMOVE_MEMBER:
      return Object.assign({}, state, { isFetching: true });
    case REMOVE_MEMBER_SUCCESS:
      var members = state.members.filter(m => m.id !== action.response.id);
      return Object.assign({}, state, {
        members,
        isFetching: false,
      });
    case REMOVE_MEMBER_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });




    //memberships and customer invites
    case REQUEST_ACCEPT_INVITE:
      return Object.assign({}, state, { isFetching: true });
    case ACCEPT_INVITE_SUCCESS:
      return Object.assign({}, state, {
        memberships: [...state.memberships, action.response],
        customerInvites: state.customerInvites.filter(inv => inv.id !== action.inviteId),
        isFetching: false,
       });
    case ACCEPT_INVITE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_REJECT_INVITE:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case REJECT_INVITE_SUCCESS:
      var customerInvites = state.customerInvites.filter(inv => inv.id !== action.response.id);
      return Object.assign({}, state, {
        customerInvites,
        isFetching: false,
      });
    case REJECT_INVITE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_REMOVE_MEMBERSHIP:
      return Object.assign({}, state, { isFetching: true });
    case REMOVE_MEMBERSHIP_SUCCESS:
      var memberships = state.memberships.filter(m => m.id !== action.response.id);
      return Object.assign({}, state, {
        memberships,
        isFetching: false,
       });
    case REMOVE_MEMBERSHIP_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case SELECT_MEMBERSHIP_SUCCESS:
      return Object.assign({}, state, {
        selectedMembership: action.response.id,
      });

    default:
      return state;
  }
}

export default reducer;
