import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';
import {
  REQUEST_GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  REQUEST_UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
} from './actionTypes';
import config from '../../config'

const API_BASE = config().API_BASE;

let actions = {
  requestGetSettings: () => ({ type: REQUEST_GET_SETTINGS }),
  getSettings: (accessToken, id) => (dispatch) => {
    dispatch(actions.requestGetSettings());
    let url, headers;
    if (accessToken) {
      const query = queryString.stringify({filter: JSON.stringify({
        include: 'equipment',
      })});
      url = `${API_BASE}/account/${id}/settings?${query}`;
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    } else {
      url = `${API_BASE}/public/settings`;
      headers = {};
    }

    return fetch(url, { headers }).then(resp => {
        if (resp.status >= 400) throw new Error(resp.status);
        return resp.json();
    }).then(json => {
      dispatch(actions.getSettingsSuccess(json));
    }).catch(err => {
      dispatch(actions.getSettingsFailure(err));
    });
  },
  getSettingsSuccess: (response) => ({
    type: GET_SETTINGS_SUCCESS,
    response,
  }),
  getSettingsFailure: (error) => ({
    type: GET_SETTINGS_FAILURE,
    error,
  }),

  requestUpdateSettings: () => ({ type: REQUEST_UPDATE_SETTINGS }),
  updateSettings: (accessToken, settingsId, data, accountId) => (dispatch) => {
    dispatch(actions.requestUpdateSettings());
    return fetch(`${API_BASE}/account/${accountId}/settings/${settingsId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your changes have been saved');
      dispatch(actions.updateSettingsSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh!', 'There was an error');
      dispatch(actions.updateSettingsFailure(err));
    });
  },
  updateSettingsSuccess: (response) => ({
    type: UPDATE_SETTINGS_SUCCESS,
    response,
  }),
  updateSettingsFailure: (error) => ({
    type: UPDATE_SETTINGS_FAILURE,
    error,
  }),
};

export default actions;
