import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';
import {
  REQUEST_GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAILURE,
  REQUEST_GET_MEMBERSHIPS,
  GET_MEMBERSHIPS_SUCCESS,
  GET_MEMBERSHIPS_FAILURE,
  REQUEST_REMOVE_MEMBER,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FAILURE,
  REQUEST_REMOVE_MEMBERSHIP,
  REMOVE_MEMBERSHIP_SUCCESS,
  REMOVE_MEMBERSHIP_FAILURE,
  REQUEST_GET_INVITES,
  GET_INVITES_SUCCESS,
  GET_INVITES_FAILURE,
  REQUEST_GET_CUSTOMER_INVITES,
  GET_CUSTOMER_INVITES_SUCCESS,
  GET_CUSTOMER_INVITES_FAILURE,
  REQUEST_INVITE_MEMBER,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_FAILURE,
  REQUEST_ACCEPT_INVITE,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  REQUEST_DELETE_INVITE,
  DELETE_INVITE_SUCCESS,
  DELETE_INVITE_FAILURE,
  REQUEST_RESEND_INVITE,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_FAILURE,
  REQUEST_REJECT_INVITE,
  REJECT_INVITE_SUCCESS,
  REJECT_INVITE_FAILURE,
  SELECT_MEMBERSHIP_SUCCESS,
} from './actionTypes';
import config from '../../config'

const API_BASE = config().API_BASE;

let actions = {
  requestGetMembers: () => ({ type: REQUEST_GET_MEMBERS }),
  getMembers: (accessToken, accountId) => (dispatch) => {
    dispatch(actions.requestGetMembers());
    return fetch(`${API_BASE}/account/${accountId}/account/${accountId}/member`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getMembersSuccess(json));
    }).catch(err => {
      dispatch(actions.getMembersFailure(err));
    });
  },
  getMembersSuccess: (response) => ({
    type: GET_MEMBERS_SUCCESS,
    response,
  }),
  getMembersFailure: (error) => ({
    type: GET_MEMBERS_FAILURE,
    error,
  }),

  requestGetMemberships: () => ({ type: REQUEST_GET_MEMBERSHIPS }),
  getMemberships: (accessToken, customerId, accountId) => (dispatch) => {
    dispatch(actions.requestGetMemberships());
    const query = queryString.stringify({ filter: JSON.stringify({
      include: 'customer',
    }) });
    return fetch(`${API_BASE}/account/${accountId}/customer/${customerId}/membership?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
        if (resp.status >= 400) throw new Error(resp.status);
        return resp.json();
    }).then(json => {
      dispatch(actions.getMembershipsSuccess(json));
    }).catch(err => {
      dispatch(actions.getMembershipsFailure(err));
    });
  },
  getMembershipsSuccess: (response) => ({
    type: GET_MEMBERSHIPS_SUCCESS,
    response,
  }),
  getMembershipsFailure: (error) => ({
    type: GET_MEMBERSHIPS_FAILURE,
    error,
  }),

  requestRemoveMember: () => ({ type: REQUEST_REMOVE_MEMBER }),
  removeMember: (accessToken, accountId, customerId) => (dispatch) => {
    dispatch(actions.requestRemoveMember());
    return fetch(`${API_BASE}/account/${accountId}/account/${accountId}/member/${customerId}/removeAssociation`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.success('Success!', 'Your changes have been saved');
      dispatch(actions.removeMemberSuccess({id: customerId}));
    }).catch(err => {
      dispatch(actions.removeMemberFailure(err));
    });
  },
  removeMemberSuccess: (response) => ({
    type: REMOVE_MEMBER_SUCCESS,
    response,
  }),
  removeMemberFailure: (error) => ({
    type: REMOVE_MEMBER_FAILURE,
    error,
  }),

  requestRemoveMembership: () => ({ type: REQUEST_REMOVE_MEMBERSHIP }),
  removeMembership: (accessToken, customerId, accountId) => (dispatch) => {
    dispatch(actions.requestRemoveMembership());
    return fetch(`${API_BASE}/account/${accountId}/customer/${customerId}/membership/${accountId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.success('Success!', 'You you have removed your membership');
      dispatch(actions.removeMembershipSuccess({id: accountId}));
    }).catch(err => {
      dispatch(actions.removeMembershipFailure(err));
    });
  },
  removeMembershipSuccess: (response) => ({
    type: REMOVE_MEMBERSHIP_SUCCESS,
    response,
  }),
  removeMembershipFailure: (error) => ({
    type: REMOVE_MEMBERSHIP_FAILURE,
    error,
  }),

  requestGetInvites: () => ({ type: REQUEST_GET_INVITES }),
  getInvites: (accessToken, accountId) => (dispatch) => {
    dispatch(actions.requestGetInvites());
    return fetch(`${API_BASE}/account/${accountId}/invite`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getInvitesSuccess(json));
    }).catch(err => {
      dispatch(actions.getInvitesFailure(err));
    });
  },
  getInvitesSuccess: (response) => ({
    type: GET_INVITES_SUCCESS,
    response,
  }),
  getInvitesFailure: (error) => ({
    type: GET_INVITES_FAILURE,
    error,
  }),

  requestGetCustomerInvites: () => ({ type: REQUEST_GET_CUSTOMER_INVITES }),
  getCustomerInvites: (accessToken, customerId, accountId) => (dispatch) => {
    dispatch(actions.requestGetCustomerInvites());
    const query = queryString.stringify({ filter: JSON.stringify({
      include: [{
        association: 'account',
        include: 'customer'
      }],
      where: { and:[
        { isAccepted: false },
        { isRejected: false },
      ] }
    }) });
    return fetch(`${API_BASE}/account/${accountId}/customer/${customerId}/membershipInvite?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getCustomerInvitesSuccess(json));
    }).catch(err => {
      dispatch(actions.getCustomerInvitesFailure(err));
    });
  },
  getCustomerInvitesSuccess: (response) => ({
    type: GET_CUSTOMER_INVITES_SUCCESS,
    response,
  }),
  getCustomerInvitesFailure: (error) => ({
    type: GET_CUSTOMER_INVITES_FAILURE,
    error,
  }),

  requestInviteMember: () => ({ type: REQUEST_INVITE_MEMBER }),
  inviteMemeber: (accessToken, accountId, email) => (dispatch) => {
    dispatch(actions.requestInviteMember());
    return fetch(`${API_BASE}/account/${accountId}/invite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ email }),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your invite has been sent');
      dispatch(actions.inviteMemeberSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh!', 'There was an error');
      dispatch(actions.inviteMemeberFailure(err));
    });
  },
  inviteMemeberSuccess: (response) => ({
    type: INVITE_MEMBER_SUCCESS,
    response,
  }),
  inviteMemeberFailure: (error) => ({
    type: INVITE_MEMBER_FAILURE,
    error,
  }),

  requestAcceptInvite: () => ({ type: REQUEST_ACCEPT_INVITE }),
  acceptInvite: (accessToken, inviteId, accountId) => (dispatch) => {
    dispatch(actions.requestAcceptInvite());
    return fetch(`${API_BASE}/account/${accountId}/invite/${inviteId}/acceptInvite`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'You have accepted the invite');
      dispatch(actions.acceptInviteSuccess(json, inviteId));
    }).catch(err => {
      toastr.warning('Uh oh', 'There was an error. Your invite was not accepted.', { timeOut: 0 });
      dispatch(actions.acceptInviteFailure(err));
    });
  },
  acceptInviteSuccess: (response, inviteId) => ({
    type: ACCEPT_INVITE_SUCCESS,
    response,
    inviteId,
  }),
  acceptInviteFailure: (error) => ({
    type: ACCEPT_INVITE_FAILURE,
    error,
  }),

  requestDeleteInvite: () => ({ type: REQUEST_DELETE_INVITE }),
  deleteInvite: (accessToken, inviteId, accountId) => (dispatch) => {
    dispatch(actions.requestDeleteInvite());
    return fetch(`${API_BASE}/account/${accountId}/invite/${inviteId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'You have deleted the invite');
      dispatch(actions.deleteInviteSuccess({id: inviteId}));
    }).catch(err => {
      dispatch(actions.deleteInviteFailure(err));
    });
  },
  deleteInviteSuccess: (response) => ({
    type: DELETE_INVITE_SUCCESS,
    response,
  }),
  deleteInviteFailure: (error) => ({
    type: DELETE_INVITE_FAILURE,
    error,
  }),

  requestResendInvite: () => ({ type: REQUEST_RESEND_INVITE }),
  resendInvite: (accessToken, inviteId, accountId) => (dispatch) => {
    dispatch(actions.requestResendInvite());
    return fetch(`${API_BASE}/account/${accountId}/invite/${inviteId}/resend`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your invite has been resent');
      dispatch(actions.resendInviteSuccess(json));
    }).catch(err => {
      dispatch(actions.resendInviteFailure(err));
    });
  },
  resendInviteSuccess: (response) => ({
    type: RESEND_INVITE_SUCCESS,
    response,
  }),
  resendInviteFailure: (error) => ({
    type: RESEND_INVITE_FAILURE,
    error,
  }),

  requestRejectInvite: () => ({ type: REQUEST_REJECT_INVITE }),
  rejectInvite: (accessToken, inviteId, accountId, customerId) => (dispatch) => {
    dispatch(actions.requestRejectInvite());
    return fetch(`${API_BASE}/account/${accountId}/customer/${customerId}/membershipInvite/${inviteId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ isRejected: true }),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'You have deleted the invite');
      dispatch(actions.rejectInviteSuccess({id: inviteId}));
    }).catch(err => {
      dispatch(actions.rejectInviteFailure(err));
    });
  },
  rejectInviteSuccess: (response) => ({
    type: REJECT_INVITE_SUCCESS,
    response,
  }),
  rejectInviteFailure: (error) => ({
    type: REJECT_INVITE_FAILURE,
    error,
  }),

  selectMembership: (accountId) => (dispatch) => {
    dispatch(actions.selectMembershipSuccess({id: accountId}));
  },
  selectMembershipSuccess: (response) => ({
    type: SELECT_MEMBERSHIP_SUCCESS,
    response,
  }),

};

export default actions;
