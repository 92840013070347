import {
  REQUEST_LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  REQUEST_LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  REQUEST_REGISTRATION,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  SET_ONLINE_STATUS,
  SET_LATEST_PATH,
} from './actionTypes';

const initialState = {
  isAuthenticated: false,
  isOnline: navigator.onLine,
  accessToken: null,
  isFetching: false,
  userId: null,
  accountId: null,
  latestPath: null,
  error: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LOGIN_USER:
      return Object.assign({}, initialState, {
        isFetching: true,
        latestPath: state.latestPath,
      });
    case LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        accessToken: action.response.token,
        userId: action.response.customerId,
        accountId: action.response.accountId,
        isFetching: false,
        isAuthenticated: true,
      });
    case LOGIN_USER_FAILURE:
      return Object.assign({}, initialState, {
        error: action.error,
        latestPath: state.latestPath,
      });

    case REQUEST_LOGOUT_USER:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case LOGOUT_USER_SUCCESS:
      return Object.assign({}, initialState, {
        latestPath: state.latestPath,
      });
    case LOGOUT_USER_FAILURE:
      return Object.assign({}, initialState, {
        error: action.error,
        latestPath: state.latestPath,
      });

    case REQUEST_REGISTRATION:
      return Object.assign({}, initialState, {
        isFetching: true,
        latestPath: state.latestPath,
      });
    case REGISTRATION_SUCCESS:
      return Object.assign({}, state, {
        accessToken: action.response.token,
        userId: action.response.customerId,
        accountId: action.response.accountId,
        isFetching: false,
        isAuthenticated: true,
      });
    case REGISTRATION_FAILURE:
      return Object.assign({}, initialState, {
        latestPath: state.latestPath,
      });

    case SET_ONLINE_STATUS:
      return Object.assign({}, state, { isOnline: navigator.onLine });

    case SET_LATEST_PATH:
      return Object.assign({}, state, { latestPath: action.path  });

    default:
      return state;
  }
}

export default reducer;
