import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';

import {
  REQUEST_GET_LISTS,
  GET_LISTS_SUCCESS,
  GET_LISTS_FAILURE,
  REQUEST_GET_LISTS_FOR_ACTIONS,
  GET_LISTS_FOR_ACTIONS_SUCCESS,
  GET_LISTS_FOR_ACTIONS_FAILURE,
  REQUEST_GET_LIST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILURE,
  REQUEST_CREATE_LIST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAILURE,
  REQUEST_DELETE_LIST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_FAILURE,
  REQUEST_UPDATE_LIST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_FAILURE,

  REQUEST_GET_LIST_ITEMS,
  GET_LIST_ITEMS_SUCCESS,
  GET_LIST_ITEMS_FAILURE,
  REQUEST_GET_LIST_ITEM,
  GET_LIST_ITEM_SUCCESS,
  GET_LIST_ITEM_FAILURE,
  REMOVE_LIST_ITEM,
  REQUEST_CREATE_LIST_ITEM,
  CREATE_LIST_ITEM_SUCCESS,
  CREATE_LIST_ITEM_FAILURE,
  REQUEST_DELETE_LIST_ITEM,
  DELETE_LIST_ITEM_SUCCESS,
  DELETE_LIST_ITEM_FAILURE,
  REQUEST_UPDATE_LIST_ITEM,
  UPDATE_LIST_ITEM_SUCCESS,
  UPDATE_LIST_ITEM_FAILURE,

  SELECT_LIST_SUCCESS,
  SELECT_LIST_ITEM_SUCCESS,
  SET_LIST_DATA,
} from './actionTypes';

import config from '../../../config'

const API_BASE = config().API_BASE;

let actions = {
  requestGetLists: () => ({ type: REQUEST_GET_LISTS }),
  getLists: (accessToken, config, accountId, filter={}) => (dispatch) => {
    dispatch(actions.requestGetLists());
    //filter.include = ['hops','yeasts','waters','fermentables','miscIngredients','customItems'];
    var query = queryString.stringify({filter: JSON.stringify(filter)});
    return fetch(`${API_BASE}/account/${accountId}/${config.apiRelationPath}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getListsSuccess(json));
    }).catch(err => {
      dispatch(actions.getListsFailure(err));
    });
  },
  getListsSuccess: (response) => ({
    type: GET_LISTS_SUCCESS,
    response,
  }),
  getListsFailure: (error) => ({
    type: GET_LISTS_FAILURE,
    error,
  }),

  requestGetListsForActions: () => ({ type: REQUEST_GET_LISTS_FOR_ACTIONS }),
  getListsForActions: (accessToken, config, accountId, filter={}) => (dispatch) => {
    dispatch(actions.requestGetListsForActions());
    var query = queryString.stringify({filter: JSON.stringify(filter)});
    return fetch(`${API_BASE}/account/${accountId}/${config.apiRelationPath}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getListsForActionsSuccess(json));
    }).catch(err => {
      dispatch(actions.getListsForActionsFailure(err));
    });
  },
  getListsForActionsSuccess: (response) => ({
    type: GET_LISTS_FOR_ACTIONS_SUCCESS,
    response,
  }),
  getListsForActionsFailure: (error) => ({
    type: GET_LISTS_FOR_ACTIONS_FAILURE,
    error,
  }),

  requestGetList: () => ({ type: REQUEST_GET_LIST }),
  getList: (accessToken, config, listId, accountId) => (dispatch) => {
    dispatch(actions.requestGetList());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiBasePath}/${listId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getListSuccess(json));
    }).catch(err => {
      dispatch(actions.getListFailure(err));
    });
  },
  getListSuccess: (response) => ({
    type: GET_LIST_SUCCESS,
    response,
  }),
  getListFailure: (error) => ({
    type: GET_LIST_FAILURE,
    error,
  }),

  requestCreateList: () => ({ type: REQUEST_CREATE_LIST }),
  createList: (accessToken, config, accountId, data) => (dispatch) => {
    dispatch(actions.requestCreateList());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiRelationPath}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your list has been saved');
      dispatch(actions.createListSuccess(json));
    }).catch(err => {
      dispatch(actions.createListFailure(err));
    });
  },
  createListSuccess: (response) => ({
    type: CREATE_LIST_SUCCESS,
    response,
  }),
  createListFailure: (error) => ({
    type: CREATE_LIST_FAILURE,
    error,
  }),

  requestDeleteList: () => ({ type: REQUEST_DELETE_LIST }),
  deleteList: (accessToken, config, listId, accountId) => (dispatch) => {
    dispatch(actions.requestDeleteList());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiBasePath}/${listId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.success('Success!', 'Your list has been deleted');
      dispatch(actions.deleteListSuccess({id: listId}));
    }).catch(err => {
      dispatch(actions.deleteListFailure(err));
    });
  },
  deleteListSuccess: (response) => ({
    type: DELETE_LIST_SUCCESS,
    response,
  }),
  deleteListFailure: (error) => ({
    type: DELETE_LIST_FAILURE,
    error,
  }),

  requestUpdateList: () => ({ type: REQUEST_UPDATE_LIST }),
  updateList: (accessToken, config, listId, data, accountId) => (dispatch) => {
    dispatch(actions.requestUpdateList());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiBasePath}/${listId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your changes have been saved');
      dispatch(actions.updateListSuccess(json));
    }).catch(err => {
      dispatch(actions.updateListFailure(err));
    });
  },
  updateListSuccess: (response) => ({
    type: UPDATE_LIST_SUCCESS,
    response,
  }),
  updateListFailure: (error) => ({
    type: UPDATE_LIST_FAILURE,
    error,
  }),

  requestGetListItems: () => ({ type: REQUEST_GET_LIST_ITEMS }),
  getListItems: (accessToken, config, accountId, listId, filter={}) => (dispatch) => {
    dispatch(actions.requestGetListItems());
    filter.include = ["hop", "yeast", "miscIngredient", "water", "customItem", "fermentable"];
    var query = queryString.stringify({filter: JSON.stringify(filter)});
    return fetch(`${API_BASE}/account/${accountId}/${config.apiRelationPath}/${listId}/item?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getListItemsSuccess(json));
    }).catch(err => {
      dispatch(actions.getListItemsFailure(err));
    });
  },
  getListItemsSuccess: (response) => ({
    type: GET_LIST_ITEMS_SUCCESS,
    response,
  }),
  getListItemsFailure: (error) => ({
    type: GET_LIST_ITEMS_FAILURE,
    error,
  }),


  requestGetListItem: () => ({ type: REQUEST_GET_LIST_ITEM }),
  getListItem: (accessToken, config, accountId, listId, listItemId, filter={}) => (dispatch) => {
    dispatch(actions.requestGetListItem());
    filter.include = ["hop", "yeast", "miscIngredient", "water", "customItem", "fermentable"];
    var query = queryString.stringify({filter: JSON.stringify(filter)});
    return fetch(`${API_BASE}/account/${accountId}/${config.apiRelationPath}/${listId}/item/${listItemId}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getListItemSuccess(json));
    }).catch(err => {
      dispatch(actions.getListItemFailure(err));
    });
  },
  getListItemSuccess: (response) => ({
    type: GET_LIST_ITEM_SUCCESS,
    response,
  }),
  getListItemFailure: (error) => ({
    type: GET_LIST_ITEM_FAILURE,
    error,
  }),

  removeListItem: () => ({ type: REMOVE_LIST_ITEM }),

  requestCreateListItem: () => ({ type: REQUEST_CREATE_LIST_ITEM }),
  createListItem: (accessToken, config, listId, data, accountId) => (dispatch) => {
    dispatch(actions.requestCreateListItem());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiBasePath}/${listId}/item`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your list item has been saved');
      dispatch(actions.createListItemSuccess(json));
    }).catch(err => {
      dispatch(actions.createListItemFailure(err));
    });
  },
  createListItemSuccess: (response) => ({
    type: CREATE_LIST_ITEM_SUCCESS,
    response,
  }),
  createListItemFailure: (error) => ({
    type: CREATE_LIST_ITEM_FAILURE,
    error,
  }),


  requestDeleteListItem: () => ({ type: REQUEST_DELETE_LIST_ITEM }),
  deleteListItem: (accessToken, config, listId, listItemId, accountId) => (dispatch) => {
    dispatch(actions.requestDeleteListItem());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiBasePath}/${listId}/item/${listItemId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.success('Success!', 'Your item has been deleted');
      dispatch(actions.deleteListItemSuccess({id: listItemId}));
    }).catch(err => {
      dispatch(actions.deleteListItemFailure(err));
    });
  },
  deleteListItemSuccess: (response) => ({
    type: DELETE_LIST_ITEM_SUCCESS,
    response,
  }),
  deleteListItemFailure: (error) => ({
    type: DELETE_LIST_ITEM_FAILURE,
    error,
  }),

  requestUpdateListItem: () => ({ type: REQUEST_UPDATE_LIST_ITEM }),
  updateListItem: (accessToken, config, listId, listItemId, data, accountId) => (dispatch) => {
    dispatch(actions.requestUpdateListItem());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiBasePath}/${listId}/item/${listItemId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.updateListItemSuccess(json));
    }).catch(err => {
      dispatch(actions.updateListItemFailure(err));
    });
  },
  updateListItemSuccess: (response) => ({
    type: UPDATE_LIST_ITEM_SUCCESS,
    response,
  }),
  updateListItemFailure: (error) => ({
    type: UPDATE_LIST_ITEM_FAILURE,
    error,
  }),

  selectList: (listId) => (dispatch) => {
    dispatch(actions.selectListSuccess({id: listId}));
  },
  selectListSuccess: (response) => ({
    type: SELECT_LIST_SUCCESS,
    response,
  }),

  selectListItem: (listItemId) => (dispatch) => {
    dispatch(actions.selectListItemSuccess({id: listItemId}));
  },
  selectListItemSuccess: (response) => ({
    type: SELECT_LIST_ITEM_SUCCESS,
    response,
  }),
  setListData: (listData={}) => ({
    type: SET_LIST_DATA,
    listData,
  }),
};

export default actions;
