let add = require('date-fns/add');
let differenceInDays = require('date-fns/differenceInDays');
add = add.default || add;
differenceInDays = differenceInDays.default || differenceInDays;

const {
  FERMENTATION_STEPS,
  UNIT_DEFAULTS_MAP,
  SALT_MAP,
  ACCOUNT_CONFIG,
} = require('./constants');

const conv = require('convert-units');

const {
  convertColor,
  specificGravityToBrix,
  brixToSpecificGravity,
  brixToRefractiveIndex,
  refractiveIndexToBrix,
  getVolumeEstimates,
  getGravity,
  tinsethIBU,
  ragerIBU,
  garetzIBU,
  finalGravity,
  abw,
  abv,
  colorEstimate,
  getEfficiency,
  caloriesFromAbv,
  caloriesFromCarbs,
  primingSugarNeeded,
  getLiquidExtractVolume,
  attenuationAdjustment,
  co2PressureNeeded,
} = require('./formulas');

exports.strToInt = (str) => {
  //this will also convert to a float
  if (typeof str !== 'string' || str === '') return str;
  if (!isNaN(str) && !isNaN(parseFloat(str))) return +str;
  return str;
}

exports.accountHasFeature = (plan, feature) => {
  if (!plan || !feature || !ACCOUNT_CONFIG.plans[plan]) return false;
  return ACCOUNT_CONFIG.plans[plan]['features'][feature] ? true : false;
}

exports.emptyStringToNull = function(obj) {
  const newObj = Object.assign({}, obj);
  Object.keys(newObj).forEach(k => {
    if (newObj[k] === '') {
      newObj[k] = null;
    }
  });
  return newObj;
}

exports.getSchedule = function(start, fermentation) {
  if (!start || !fermentation) return;
  const ferm = fermentation;
  const startDate = start;
  const today = new Date();
  const profileName = ferm.name;
  const dayCounts = [];
  const fermentationSteps = [];
  FERMENTATION_STEPS.forEach(fs => {
    if (typeof ferm[fs.dayCountKey] === 'undefined' || ferm[fs.dayCountKey] > 0) {
      dayCounts.push(ferm[fs.dayCountKey] || 0);
      fermentationSteps.push(fs);
    }
  });

  //const filteredSteps = FERMENTATION_STEPS.filter((fs, i) => !fermentation[fs.dayCountKey] || );
  const totalDays = dayCounts.reduce((acc, curr) => acc + curr);
  const endDate = add(start, { days: totalDays });
  const daysSoFar = differenceInDays(today, startDate);

  //brew day is default
  let numberOfSteps = 0;
  let steps = [];
  let schedule = [];
  let currentStep = null;
  let nextStep = null;
  let nextStepDate = null;
  //get current step
  let counter = 0;
  dayCounts.forEach((c, i) => {
    const isBrewDay = i === 1;
    const isDrinkingDay = i === (dayCounts.length - 1);
    //skipping pre brew day
    if (i > 0) { //count brew day even though its 0 days
      steps.push(fermentationSteps[i]['display']);
      schedule.push({
        display: fermentationSteps[i]['display'],
        days: c,
        key: fermentationSteps[i]['dayCountKey'],
        start: add(start, { days: counter }),
        end: add(start, { days: counter + c }),
      });
      numberOfSteps++;
    }
    //pre brew day
    if (daysSoFar < 0 && i === 0) {
      currentStep = fermentationSteps[0]['display'];
      nextStep = fermentationSteps[1] ? fermentationSteps[1]['display'] : null;
      nextStepDate = start;
    //on brew day
    } else if (daysSoFar === 0 && i === 1) {
      currentStep = fermentationSteps[1]['display'];
      nextStep = fermentationSteps[2] ? fermentationSteps[2]['display'] : null;
      nextStepDate = start;
    //anything post brew day
    } else if (daysSoFar > counter && daysSoFar <= (counter + c)) {
      currentStep = fermentationSteps[i]['display'];
      nextStep = fermentationSteps[i+1] ? fermentationSteps[i+1]['display'] : null;
      nextStepDate = add(start, { days: counter + c + 1 });
    }
    counter += c;
  });

  return {
    isActive: daysSoFar <= totalDays && totalDays > 1,
    currentStep,
    nextStep,
    nextStepDate,
    numberOfSteps,
    steps,
    endDate,
    startDate,
    totalDays,
    daysSoFar,
    schedule,
    profileName,
  }
}


exports.convert = function(from, to, num, reverse) {
  const key = from + to;
  //need to add custom convert
  if (from === to || num === 0) {
    return num;
  }

  /*
    color
    gravity
    refractometer
  */
  //custom conversion
  if (key === 'SRMEBC') {
    return convertColor(num);
  } else if (key === 'EBCSRM') {
    return convertColor(num, true);
  } else if (key === 'SGPlato' || key === 'SGBrix') {
    return specificGravityToBrix(num);
  } else if (key === 'PlatoSG' || key === 'BrixSG') {
    return brixToSpecificGravity(num);
  } else if (key === 'BrixPlato' || key === 'PlatoBrix') {
    return num;
  } else if (key === 'BrixRI') {
    return brixToRefractiveIndex(num);
  } else if (key === 'RIBrix') {
    return refractiveIndexToBrix(num);
  } else if (key === 'RISG') {
    const brix = refractiveIndexToBrix(num);
    return brixToSpecificGravity(brix);
  } else if (key === 'SGRI') {
    const brix = specificGravityToBrix(num);
    return brixToRefractiveIndex(brix);
  }

  //standard conversion
  if (reverse) {
    return conv(num).from(to).to(from);
  } else {
    return conv(num).from(from).to(to);
  }
}

exports.guidGenerator = function() {
  var S4 = function() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

exports.getRecipeWaterDetails = function(recipe) {
  const waters = recipe.waters;

  //assuming waters are combined
  const totalWaterVol = waters.reduce((acc, curr) => acc + curr.form.amount, 0);
  const waterAnalysis = waters
    .map(cw => {
      const perc = cw.form.amount / totalWaterVol;
      return {
          ph: cw.item.ph * perc,
          calcium: cw.item.calcium * perc,
          magnesium: cw.item.magnesium * perc,
          sodium: cw.item.sodium * perc,
          sulfate: cw.item.sulfate * perc,
          chloride: cw.item.chloride * perc,
          bicarb: cw.item.bicarb * perc,
      };
    }).reduce((acc, curr) => {
      if (acc) {
        acc.ph += curr.ph;
        acc.calcium += curr.calcium;
        acc.magnesium += curr.magnesium;
        acc.sodium += curr.sodium;
        acc.sulfate += curr.sulfate;
        acc.chloride += curr.chloride;
        acc.bicarb += curr.bicarb;
      } else {
        acc = curr;
      }
      return acc;
    }, {});


  //filter water agents
  const agents = Object.values(recipe.miscIngredients
    .filter(mi => SALT_MAP[mi.item.name])
    .map(a => {
      return {
        name: SALT_MAP[a.item.name],
        display: a.item.name,
        amount: exports.convert(a.form.units, UNIT_DEFAULTS_MAP.salts, a.form.amount),
        units: UNIT_DEFAULTS_MAP.salts,
      }
    })
    .reduce((acc, curr) => {
      if (acc[curr.name]) {
        acc[curr.name]['amount'] += curr.amount;
      } else {
        acc[curr.name] = curr;
      }
      return acc;
    }, {}));


  return {
    waters,
    agents,
    waterAnalysis,
  }
}

exports.getFormattedFermentablesFromRecipe = function({ fermentables, steepingEfficiency }) {
  return fermentables.filter(f => {
    const use = f.form.use || f.item.use || 'Mash';
    const amount = f.form.amount;
    return (use === 'Mash' || use === 'Boil' || use === 'Steep') && amount;
  }).map(f => {
    const lb = exports.convert(UNIT_DEFAULTS_MAP.weight, 'lb', f.form.amount);
    let fYield = f.form.yield || f.item.yield;
    if (f.use === 'Steep') {
      fYield = settings.steepingEfficiency;
    }
    return {
      lb,
      oz: parseFloat(f.form.amount),
      yield: fYield,
      srm: f.form.color || f.item.color,
      use: f.form.use || f.item.use || 'Mash',
      type: f.item.type || 'Mash',
    }
  });
}

exports.getRecipeEstimates = function(settings, recipe, sessionEfficiency) {
  if (!recipe.equipment) return {};
  const efficiency = sessionEfficiency || settings.brewhouseEfficiency;
  const fermentables = recipe.fermentables;
  const hops = recipe.hops;
  const yeasts = recipe.yeasts;
  const batchVol = exports.convert(UNIT_DEFAULTS_MAP.batchVol, 'gal', recipe.batchVol || recipe.equipment.item.batchVol);
  const boilTime = recipe.boilTime || recipe.equipment.item.boilTime;


  const formattedHops = hops.filter(h => {
    const use = h.form.use || h.item.use;
    return use === 'Boil' || use === 'Steep' || use === 'Mash' || use === 'First Wort' || use === 'Steep/Whirlpool';
  }).map(h => {
    const hopForm = h.form.form || h.item.form;
    const hopUse = h.form.use || h.item.use;
    let adjustment = 0;
    switch (hopForm) {
      case 'Pellet':
        adjustment = settings.pelletHopAdj;
        break;
      case 'Leaf':
        adjustment = settings.leafHopAdj;
        break;
      case 'Plug':
        adjustment = settings.plugHopAdj;
        break;
    }

    switch (hopUse) {
      case 'Mash':
        adjustment += settings.mashHopAdj;
        break;
      case 'First Wort':
        adjustment += settings.firstWortHopAdj;
        break;
      case 'Steep/Whirlpool':
        adjustment += -50;
        break;
    }

    adjustment = adjustment/100;
    return {
      mass: parseFloat(h.form.amount),
      alpha: parseFloat(h.form.alpha)/100 || parseFloat(h.item.alpha)/100,
      time: parseInt(h.form.boilTime),
      adjustment,
    };
  });

  const formattedGrains = exports.getFormattedFermentablesFromRecipe({
    fermentables: recipe.fermentables,
    steepingEfficiency: settings.steepingEfficiency
  });
  const mashGrains = formattedGrains.filter(g => g.use === 'Mash');
  const boilFermentables = formattedGrains.filter(g => g.use === 'Boil');

  const grainWeight = formattedGrains.reduce((acc, g) => acc + g.oz, 0);
  const mashGrainWeight = formattedGrains.reduce((acc, g) => g.use === 'Mash' && (g.type === 'Grain' || g.type === 'Adjunct' || g.type === 'Single Stage') ? acc + g.oz : acc, 0);
  const steepGrainWeight = formattedGrains.reduce((acc, g) => g.use === 'Steep' && (g.type === 'Grain' || g.type === 'Adjunct' || g.type === 'Single Stage') ? acc + g.oz : acc, 0);
  const extractWeight = formattedGrains.reduce((acc, g) => g.use === 'Boil' && g.type === 'Extract' ? acc + g.oz : acc, 0);
  const extractVolume = getLiquidExtractVolume({ weight: extractWeight });

  const grainAbsorption = recipe.mash && recipe.mash.item.biab ? settings.biabGrainAbsorption : settings.grainAbsorption;

  const volumes = getVolumeEstimates({
    batchSize: parseFloat(recipe.batchVol || recipe.equipment.item.batchVol),
    grainWeight: parseFloat(grainWeight),
    boilTime: parseInt(boilTime),
    equipment: recipe.equipment.item,
    grainAbsorption: parseFloat(grainAbsorption),
    steepGrainWeight,
    extractVolume,
    settings,
    type: recipe.type,
    mash: recipe.mash ? recipe.mash.item : null,
    equipment: recipe.equipment.item,
  });

  let attenuation = yeasts.reduce((acc, curr) => {
    return acc + ((curr.item.minAttenuation + curr.item.maxAttenuation) / 2);
  }, 0);

  if (attenuation) {
    attenuation = attenuation / yeasts.length;
    if (recipe.mash && recipe.mash.item && recipe.mash.item.steps && settings.adjustFgMashTemp) {
      attenuation += attenuationAdjustment(settings, recipe.mash.item.steps);
    }
  } else {
    attenuation = 75;
  }

  let boilFermentablesGravity = 1;
  if (boilFermentables.length > 0 && efficiency && volumes.batchSize) {
    boilFermentablesGravity = getGravity(boilFermentables, 1, exports.convert(UNIT_DEFAULTS_MAP.batchVol, 'gal', volumes.batchSize));
  }

  let originalGravity;
  if (mashGrains.length > 0 && efficiency && volumes.batchSize) {
    originalGravity = getGravity(mashGrains, (parseInt(efficiency)/100), exports.convert(UNIT_DEFAULTS_MAP.batchVol, 'gal', volumes.batchSize));
    originalGravity = originalGravity + (boilFermentablesGravity - 1);
  }

  let postMashGravity;
  if (formattedGrains.length > 0 && efficiency && volumes.preBoilVolume) {
    const originalGravityPts = (originalGravity-1)*1000;
    const boilFermentablesGravityPts = (boilFermentablesGravity-1)*1000;
    const totalPts = originalGravityPts - boilFermentablesGravityPts;
    postMashGravity = (((volumes.preBoilVolume - volumes.boilOffVolume - volumes.shrinkage) * totalPts) / volumes.preBoilVolume) / 1000 + 1;
  }

  let mashEfficiency;
  if (mashGrains.length > 0 && postMashGravity && volumes.preBoilVolume) {
    mashEfficiency = getEfficiency({
      fermentables: mashGrains,
      gravity: postMashGravity,
      volume: exports.convert(UNIT_DEFAULTS_MAP.batchVol, 'gal', volumes.preBoilVolume),
    });
  }


  let preBoilGravity;
  if (formattedGrains.length > 0 && efficiency && volumes.preBoilVolume) {
    const originalGravityPts = (originalGravity-1)*1000;
    preBoilGravity = (((volumes.preBoilVolume - volumes.boilOffVolume - volumes.shrinkage) * originalGravityPts) / volumes.preBoilVolume) / 1000 + 1;
  }

  let bitterness;
  if (originalGravity && batchVol && formattedHops.length > 0) {
    //batchSize, gainWeight, boilTime, equipment, grainAbsorption
    const hopUtil = recipe.equipment && recipe.equipment.item && recipe.equipment.item.hopUtil ? recipe.equipment.item.hopUtil : 100;
    if (settings.bitternessFormula === 'Tinseth') {
      bitterness = tinsethIBU(originalGravity, batchVol, formattedHops, settings.elevation);
    } else if (settings.bitternessFormula === 'Rager') {
      bitterness = ragerIBU(originalGravity, batchVol, formattedHops, settings.elevation);
    } else if (grainWeight && boilTime && recipe.equipment && settings.grainAbsorption && settings.bitternessFormula === 'Garetz') {
      const tinseth = tinsethIBU(originalGravity, batchVol, formattedHops, settings.elevation);
      const rager = ragerIBU(originalGravity, batchVol, formattedHops, settings.elevation);
      const desiredIbus = (tinseth + rager) / 2;
      const boilVolGal = exports.convert(UNIT_DEFAULTS_MAP.weight, 'gal', volumes.preBoilVolume);
      bitterness = garetzIBU(originalGravity, boilVolGal, batchVol, desiredIbus, settings.elevation, formattedHops);
    }
    bitterness = bitterness * (hopUtil/100);
  }

  let abvVal;
  let fg;
  if (formattedGrains.length && attenuation && efficiency && batchVol && originalGravity) {
    fg = finalGravity(originalGravity, (attenuation/100));
    const abwVal = abw(originalGravity, fg);
    abvVal = abv(abwVal, fg);
  }

  let estimatedCalories;
  if (originalGravity && fg) {
    estimatedCalories = caloriesFromAbv(originalGravity, fg) + caloriesFromCarbs(originalGravity, fg);
  }

  let color;
  if (formattedGrains.length > 0 && batchVol) {
    color = Math.round(colorEstimate(formattedGrains, parseFloat(batchVol)));
  }

  let desiredCarb;
  if (recipe.style) {
    desiredCarb = (recipe.style.item.minCarb+recipe.style.item.maxCarb)/2;
  }

  let primingSugarAmount;
  if (desiredCarb && recipe.carbonation && recipe.carbonation.item.temperature && volumes.bottlingVolume) {
    const sugars = primingSugarNeeded({
      temp: recipe.carbonation.item.temperature,
      volumes: desiredCarb,
      batchVolume: exports.convert(UNIT_DEFAULTS_MAP.batchVol, 'l', volumes.bottlingVolume),
    });
    primingSugarAmount = sugars[recipe.carbonation.item.name];
  }

  let co2Pressure;
  if (desiredCarb && recipe.carbonation && recipe.carbonation.item.temperature) {
    co2Pressure = co2PressureNeeded({
      temp: recipe.carbonation.item.temperature,
      volumes: desiredCarb,
    });
  }

  return {
    grainWeight,
    mashGrainWeight,
    attenuation,
    abv: abvVal,
    bitterness,
    color,
    mashEfficiency,
    postMashGravity,
    preBoilGravity,
    originalGravity,
    finalGravity: fg,
    estimatedCalories,
    brewhouseEfficiency: efficiency,
    desiredCarb,
    co2Pressure,
    primingSugarAmount,
    ...volumes,
  };
}

exports.beerxmlToBrewuiKeys = (data) => {
  if (data instanceof Array) {
    data.map(d => exports.beerxmlToBrewuiKeys(d));
  } else {
    for (const key in data) {
      const newKey = key.toLowerCase().replace(/(\_[a-z])/g, ($1) => $1.toUpperCase().replace('_', ''));
      data[newKey] = data[key];
      if (key !== newKey) {
        delete data[key];
      }
      if (typeof data[newKey] === 'object') {
        data[newKey] = exports.beerxmlToBrewuiKeys(data[newKey]);
      }
    }
  }
  return data;
}
