/*global fbq,gtag*/
import { ACCOUNT_CONFIG } from 'brewui-common/constants';

//$5 /100 users
const trialValue = 0.05;
//$5m * 6m / 100 users
const trialLTVValue = 0.3;

export function trackStartTrial() {

  if (fbq) {
    fbq('track', 'StartTrial', {
      currency: "USD",
      predicted_ltv: trialLTVValue,
      value: trialValue
    });
  }
  
  if (gtag) {
    gtag('event', 'start_trial');
  }
}

export function trackSubscribe(level, interval) {
  const plan = ACCOUNT_CONFIG.plans[level];
  let price, ltv;

  if (interval === 'yearly') {
    price = plan['yearlyPrice'];
    ltv = price;
  } else {
    price = plan['monthlyPrice'];
    ltv = parseFloat((price * 6).toFixed(2));
  }

  if (fbq) {
    fbq('track', 'Subscribe', {
      currency: "USD",
      predicted_ltv: ltv,
      value: price,
    });
  }
  if (gtag) {
    gtag('event', 'purchased_subscription');
  }
}
