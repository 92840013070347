//GET /Accounts/{id}/members
export const REQUEST_GET_MEMBERS = 'REQUEST_GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAILURE = 'GET_MEMBERS_FAILURE';

//GET /Customers/{id}/memberships
export const REQUEST_GET_MEMBERSHIPS = 'REQUEST_GET_MEMBERSHIPS';
export const GET_MEMBERSHIPS_SUCCESS = 'GET_MEMBERSHIPS_SUCCESS';
export const GET_MEMBERSHIPS_FAILURE = 'GET_MEMBERSHIPS_FAILURE';

//DELETE /Accounts/{id}/members/rel/{fk}
export const REQUEST_REMOVE_MEMBER = 'REQUEST_REMOVE_MEMBER';
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_FAILURE = 'REMOVE_MEMBER_FAILURE';

//DELETE /Customer/{id}/memberships/rel/{fk}
export const REQUEST_REMOVE_MEMBERSHIP = 'REQUEST_REMOVE_MEMBERSHIP';
export const REMOVE_MEMBERSHIP_SUCCESS = 'REMOVE_MEMBERSHIP_SUCCESS';
export const REMOVE_MEMBERSHIP_FAILURE = 'REMOVE_MEMBERSHIP_FAILURE';

//GET /Account/{id}/invites
export const REQUEST_GET_INVITES = 'REQUEST_GET_INVITES';
export const GET_INVITES_SUCCESS = 'GET_INVITES_SUCCESS';
export const GET_INVITES_FAILURE = 'GET_INVITES_FAILURE';

//GET /Customer/{id}/invites
export const REQUEST_GET_CUSTOMER_INVITES = 'REQUEST_GET_CUSTOMER_INVITES';
export const GET_CUSTOMER_INVITES_SUCCESS = 'GET_CUSTOMER_INVITES_SUCCESS';
export const GET_CUSTOMER_INVITES_FAILURE = 'GET_CUSTOMER_INVITES_FAILURE';

//custom hook required
//POST /Account/${accountId}/invites
export const REQUEST_INVITE_MEMBER = 'REQUEST_INVITE_MEMBER';
export const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS';
export const INVITE_MEMBER_FAILURE = 'INVITE_MEMBER_FAILURE';

//custom endpoint required / accpet invite and add memeber
//PATCH /Invites/{id}/acceptInvite
export const REQUEST_ACCEPT_INVITE = 'REQUEST_ACCEPT_INVITE';
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_FAILURE = 'ACCEPT_INVITE_FAILURE';

//DELETE /Invites/{id}
export const REQUEST_DELETE_INVITE = 'REQUEST_DELETE_INVITE';
export const DELETE_INVITE_SUCCESS = 'DELETE_INVITE_SUCCESS';
export const DELETE_INVITE_FAILURE = 'DELETE_INVITE_FAILURE';

//custom endpoint
//POST /Invites/{id}/resend
export const REQUEST_RESEND_INVITE = 'REQUEST_RESEND_INVITE';
export const RESEND_INVITE_SUCCESS = 'RESEND_INVITE_SUCCESS';
export const RESEND_INVITE_FAILURE = 'RESEND_INVITE_FAILURE';

//DELETE /Invites/{id}
export const REQUEST_REJECT_INVITE = 'REQUEST_REJECT_INVITE';
export const REJECT_INVITE_SUCCESS = 'REJECT_INVITE_SUCCESS';
export const REJECT_INVITE_FAILURE = 'REJECT_INVITE_FAILURE';

export const SELECT_MEMBERSHIP_SUCCESS = 'SELECT_MEMBERSHIP_SUCCESS';
