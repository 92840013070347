import {
  REQUEST_GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE,
  REQUEST_UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  REQUEST_CLOSE_ACCOUNT,
  CLOSE_ACCOUNT_SUCCESS,
  CLOSE_ACCOUNT_FAILURE,
  REQUEST_UPDATE_LEVEL,
  UPDATE_LEVEL_SUCCESS,
  UPDATE_LEVEL_FAILURE,
  REQUEST_GET_BILLING,
  GET_BILLING_SUCCESS,
  GET_BILLING_FAILURE,
  REQUEST_GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  REQUEST_SUBSCRIBE,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  REQUEST_UPDATE_CARD,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAILURE,
} from './actionTypes';

import {
  REQUEST_LOGOUT_USER,
} from '../Auth/actionTypes';

const initialState = {
  data: null,
  subscription: null,
  isFetching: false,
  savedCard: false,
  error: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LOGOUT_USER:
      return Object.assign({}, initialState);

    case REQUEST_GET_ACCOUNT:
      return Object.assign({}, state, { isFetching: true });
    case GET_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        data: Object.assign({}, action.response),
        isFetching: false,
      });
    case GET_ACCOUNT_FAILURE:
      return Object.assign({}, initialState, {
        error: action.error,
        isFetching: false,
      });

    case REQUEST_UPDATE_ACCOUNT:
      return Object.assign({}, state, { isFetching: true });
    case UPDATE_ACCOUNT_SUCCESS:
      const billing = state.data.billing;
      const account = action.response;
      account.billing = billing;
      return Object.assign({}, state, {
        data: Object.assign({}, account),
        isFetching: false,
      });
    case UPDATE_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      });

    case REQUEST_CLOSE_ACCOUNT:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CLOSE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        data: action.response,
        isFetching: false,
      });
    case CLOSE_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      });

    case REQUEST_UPDATE_LEVEL:
      return Object.assign({}, state, { isFetching: true });
    case UPDATE_LEVEL_SUCCESS:
      return Object.assign({}, state, {
        data: action.response,
        isFetching: false,
      });
    case UPDATE_LEVEL_FAILURE:
      return Object.assign({}, initialState, {
        error: action.error,
      });


    case REQUEST_GET_BILLING:
      return Object.assign({}, state, { isFetching: true });
    case GET_BILLING_SUCCESS:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, { billing: action.response[0] }),
        isFetching: false,
      });
    case GET_BILLING_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      });
    case REQUEST_GET_SUBSCRIPTION:
      return Object.assign({}, state, { isFetching: true });
    case GET_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        subscription: action.response ? Object.assign({}, state.subscription, action.response ) : null,
        isFetching: false,
      });
    case GET_SUBSCRIPTION_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      });

    case REQUEST_SUBSCRIBE:
      return Object.assign({}, state, { isFetching: true });
    case SUBSCRIBE_SUCCESS:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          level: action.response.level,
          billing: action.response.billing,
        }),
        isFetching: false,
      });
    case SUBSCRIBE_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      });

    case REQUEST_UPDATE_CARD:
      return Object.assign({}, state, {
        isFetching: true,
        savedCard: false,
      });
    case UPDATE_CARD_SUCCESS:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, { billing: action.response }),
        isFetching: false,
        savedCard: true,
      });
    case UPDATE_CARD_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
        savedCard: false,
      });
    default:
      return state;
  }
}

export default reducer;
