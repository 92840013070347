import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';

import {
  REQUEST_GET_SESSIONS,
  GET_SESSIONS_SUCCESS,
  GET_SESSIONS_FAILURE,
  REQUEST_GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILURE,
  REQUEST_CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAILURE,
  REQUEST_DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAILURE,
  REQUEST_UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILURE,
  REQUEST_ATTACH_RECIPE,
  ATTACH_RECIPE_SUCCESS,
  ATTACH_RECIPE_FAILURE,
  REQUEST_ACTIVE_SESSIONS,
  ACTIVE_SESSIONS_SUCCESS,
  ACTIVE_SESSIONS_FAILURE,

  REQUEST_CREATE_READING,
  CREATE_READING_SUCCESS,
  CREATE_READING_FAILURE,
  REQUEST_DELETE_READING,
  DELETE_READING_SUCCESS,
  DELETE_READING_FAILURE,

  REMOVE_SESSION_DATA,
} from './actionTypes';
import { emptyStringToNull } from 'brewui-common/utils';
import config from '../../config'

const API_BASE = config().API_BASE;

let actions = {
  cleanupSession: (keys=[]) => ({
    type: REMOVE_SESSION_DATA,
    keys,
  }),
  requestGetSessions: () => ({ type: REQUEST_GET_SESSIONS }),
  getSessions: (accessToken, filter={}, accountId) => (dispatch) => {
    dispatch(actions.requestGetSessions());
    var query = queryString.stringify({
      filter: JSON.stringify(filter),
    });
    return fetch(`${API_BASE}/account/${accountId}/session?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getSessionsSuccess(json));
    }).catch(err => {
      dispatch(actions.getSessionsFailure(err));
    });
  },
  getSessionsSuccess: (response) => ({
    type: GET_SESSIONS_SUCCESS,
    response,
  }),
  getSessionsFailure: (error) => ({
    type: GET_SESSIONS_FAILURE,
    error,
  }),

  requestGetSession: () => ({ type: REQUEST_GET_SESSION }),
  getSession: (accessToken, sessionId, filter, accountId) => (dispatch) => {
    dispatch(actions.requestGetSession());
    let url, headers;
    if (accessToken) {
      var query = queryString.stringify({filter: JSON.stringify(filter)});
      url = `${API_BASE}/account/${accountId}/session/${sessionId}?${query}`;
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    } else {
      url = `${API_BASE}/public/session`;
      headers = {};
    }

    return fetch(url, { headers }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getSessionSuccess(json));
    }).catch(err => {
      dispatch(actions.getSessionFailure(err));
    });
  },
  getSessionSuccess: (response) => ({
    type: GET_SESSION_SUCCESS,
    response,
  }),
  getSessionFailure: (error) => ({
    type: GET_SESSION_FAILURE,
    error,
  }),

  requestCreateSession: () => ({ type: REQUEST_CREATE_SESSION }),
  createSession: (accessToken, data, accountId) => (dispatch) => {
    dispatch(actions.requestCreateSession());
    return fetch(`${API_BASE}/account/${accountId}/session`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your session has been saved');
      dispatch(actions.createSessionSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh', 'There was an error. Your session was not created.', { timeOut: 0 });
      dispatch(actions.createSessionFailure(err));
    });
  },
  createSessionSuccess: (response) => ({
    type: CREATE_SESSION_SUCCESS,
    response,
  }),
  createSessionFailure: (error) => ({
    type: CREATE_SESSION_FAILURE,
    error,
  }),

  requestDeleteSession: () => ({ type: REQUEST_DELETE_SESSION }),
  deleteSession: (accessToken, sessionId, accountId) => (dispatch) => {
    dispatch(actions.requestDeleteSession());
    return fetch(`${API_BASE}/account/${accountId}/session/${sessionId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.success('Success!', 'Your session has been deleted');
      dispatch(actions.deleteSessionSuccess({id: sessionId}));
    }).catch(err => {
      dispatch(actions.deleteSessionFailure(err));
    });
  },
  deleteSessionSuccess: (response) => ({
    type: DELETE_SESSION_SUCCESS,
    response,
  }),
  deleteSessionFailure: (error) => ({
    type: DELETE_SESSION_FAILURE,
    error,
  }),

  requestUpdateSession: () => ({ type: REQUEST_UPDATE_SESSION }),
  updateSession: (accessToken, sessionId, data, accountId) => (dispatch) => {
    const formattedData = emptyStringToNull(data);
    dispatch(actions.requestUpdateSession());
    return fetch(`${API_BASE}/account/${accountId}/session/${sessionId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(formattedData),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your session has been saved');
      dispatch(actions.updateSessionSuccess(json));
    }).catch(err => {
      dispatch(actions.updateSessionFailure(err));
    });
  },
  updateSessionSuccess: (response) => ({
    type: UPDATE_SESSION_SUCCESS,
    response,
  }),
  updateSessionFailure: (error) => ({
    type: UPDATE_SESSION_FAILURE,
    error,
  }),

  requestAttachRecipe: () => ({ type: REQUEST_ATTACH_RECIPE }),
  attachRecipe: (accessToken, sessionId, recipeId, accountId) => (dispatch) => {
    dispatch(actions.requestAttachRecipe());
    return fetch(`${API_BASE}/account/${accountId}/session/${sessionId}/attachRecipe`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ recipeId }),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your session has been saved');
      dispatch(actions.attachRecipeSuccess(json));
    }).catch(err => {
      dispatch(actions.attachRecipeFailure(err));
    });
  },
  attachRecipeSuccess: (response) => ({
    type: ATTACH_RECIPE_SUCCESS,
    response,
  }),
  attachRecipeFailure: (error) => ({
    type: ATTACH_RECIPE_FAILURE,
    error,
  }),

  requestActiveSessions: () => ({ type: REQUEST_ACTIVE_SESSIONS }),
  activeSessions: (accessToken, accountId) => (dispatch) => {
    dispatch(actions.requestActiveSessions());
    return fetch(`${API_BASE}/account/${accountId}/session/active`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.activeSessionsSuccess(json));
    }).catch(err => {
      dispatch(actions.activeSessionsFailure(err));
    });
  },
  activeSessionsSuccess: (response) => ({
    type: ACTIVE_SESSIONS_SUCCESS,
    response,
  }),
  activeSessionsFailure: (error) => ({
    type: ACTIVE_SESSIONS_FAILURE,
    error,
  }),

  requestCreateReading: () => ({ type: REQUEST_CREATE_READING }),
  createReading: (accessToken, sessionId, data, accountId) => (dispatch) => {
    dispatch(actions.requestCreateReading());
    return fetch(`${API_BASE}/account/${accountId}/session/${sessionId}/reading`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your reading has been saved');
      dispatch(actions.createReadingSuccess(json));
    }).catch(err => {
      dispatch(actions.createReadingFailure(err));
    });
  },
  createReadingSuccess: (response) => ({
    type: CREATE_READING_SUCCESS,
    response,
  }),
  createReadingFailure: (error) => ({
    type: CREATE_READING_FAILURE,
    error,
  }),

  requestDeleteReading: () => ({ type: REQUEST_DELETE_READING }),
  deleteReading: (accessToken, sessionId, readingId, accountId) => (dispatch) => {
    dispatch(actions.requestDeleteReading());
    return fetch(`${API_BASE}/account/${accountId}/session/${sessionId}/reading/${readingId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.success('Success!', 'Your reading has been deleted');
      dispatch(actions.deleteReadingSuccess({id: sessionId}));
    }).catch(err => {
      dispatch(actions.deleteReadingFailure(err));
    });
  },
  deleteReadingSuccess: (response) => ({
    type: DELETE_READING_SUCCESS,
    response,
  }),
  deleteReadingFailure: (error) => ({
    type: DELETE_READING_FAILURE,
    error,
  }),

};

export default actions;
