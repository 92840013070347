import {
  REQUEST_SEND_FORGOT_EMAIL,
  SEND_FORGOT_EMAIL_SUCCESS,
  SEND_FORGOT_EMAIL_FAILURE,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from './actionTypes';

const initialState = {
  passwordResetSuccess: false,
  isFetching: false,
  error: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SEND_FORGOT_EMAIL:
      return Object.assign({}, state, {
        isFetching: true,
        passwordResetSuccess: false,
      });
    case SEND_FORGOT_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        passwordResetSuccess: false,
      });
    case SEND_FORGOT_EMAIL_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        passwordResetSuccess: false,
        isFetching: false,
      });

    case REQUEST_RESET_PASSWORD:
      return Object.assign({}, state, {
        passwordResetSuccess: false,
        isFetching: true,
      });
    case RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        passwordResetSuccess: true,
        isFetching: false,
      });
    case RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        passwordResetSuccess: false,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default reducer;
