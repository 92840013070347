// @flow
import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import thunkMiddleware from 'redux-thunk';
import persistState from 'redux-localstorage';

//reducers
import { authActions, authReducer } from '../views/Auth';
import { forgotPasswordActions, forgotPasswordReducer } from '../views/ForgotPassword';
import { accountActions, accountReducer } from '../views/Account';
import { customerActions, customerReducer } from '../views/Profile';
import { teamActions, teamReducer } from '../views/Team';
import { notesActions, notesReducer } from '../views/Management/Notes';
import { catalogActions, catalogReducer } from '../views/Global/Catalog';
import { settingsActions, settingsReducer } from '../views/Settings';
import { listsActions, listsReducer } from '../views/Global/Lists';
import { recipeActions, recipeReducer } from '../views/Library/Recipes';
import { styleActions, styleReducer } from '../views/Library/Styles';
import { sessionActions, sessionReducer } from '../views/BrewSession';

const actions = Object.assign(
  {},
  authActions,
  forgotPasswordActions,
  accountActions,
  customerActions,
  teamActions,
  notesActions,
  catalogActions,
  settingsActions,
  listsActions,
  recipeActions,
  styleActions,
  sessionActions,
);

//reducers
const rootReducer = combineReducers({
  authentication: authReducer,
  account: accountReducer,
  toastr: toastrReducer,
  forgotPasswordStatus: forgotPasswordReducer,
  customer: customerReducer,
  team: teamReducer,
  notes: notesReducer,
  catalog: catalogReducer,
  settings: settingsReducer,
  lists: listsReducer,
  recipes: recipeReducer,
  styles: styleReducer,
  sessions: sessionReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunkMiddleware),
  persistState('authentication'),
);

export const store = createStore(rootReducer, enhancer);
export { actions };
