export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

//account
export const REQUEST_GET_ACCOUNT = 'REQUEST_GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE';

export const REQUEST_UPDATE_ACCOUNT = 'REQUEST_UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';

export const REQUEST_CLOSE_ACCOUNT = 'REQUEST_UPDATE_ACCOUNT';
export const CLOSE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const CLOSE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';

export const REQUEST_UPDATE_LEVEL = 'REQUEST_UPDATE_LEVEL';
export const UPDATE_LEVEL_SUCCESS = 'UPDATE_LEVEL_SUCCESS';
export const UPDATE_LEVEL_FAILURE = 'UPDATE_LEVEL_FAILURE';

export const REQUEST_GET_BILLING = 'REQUEST_GET_BILLING';
export const GET_BILLING_SUCCESS = 'GET_BILLING_SUCCESS';
export const GET_BILLING_FAILURE = 'GET_BILLING_FAILURE';

export const REQUEST_GET_SUBSCRIPTION = 'REQUEST_GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAILURE = 'GET_SUBSCRIPTION_FAILURE';

export const REQUEST_SUBSCRIBE = 'REQUEST_SUBSCRIBE';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE';

export const REQUEST_UPDATE_CARD = 'REQUEST_UPDATE_CARD';
export const UPDATE_CARD_SUCCESS = 'UPDATE_CARD_SUCCESS';
export const UPDATE_CARD_FAILURE = 'UPDATE_CARD_FAILURE';