import { toastr } from 'react-redux-toastr';
import {
  REQUEST_SEND_FORGOT_EMAIL,
  SEND_FORGOT_EMAIL_SUCCESS,
  SEND_FORGOT_EMAIL_FAILURE,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from './actionTypes';
import config from '../../config';
const API_BASE = config().API_BASE;
const FORGOT_EMAIL_PATH = `${API_BASE}/auth/resetPassword`;
const RESET_PASSWORD_PATH = `${API_BASE}/auth/updatePassword`;

let actions = {
  requestSendForgotEmail: () => ({ type: REQUEST_SEND_FORGOT_EMAIL }),
  sendForgotEmail: (email) => (dispatch) => {
    toastr.info('Email Sent', 'Click the link in the email to reset your password.');
    dispatch(actions.requestSendForgotEmail());
    return fetch(FORGOT_EMAIL_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ email }),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      dispatch(actions.sendForgotEmailSuccess());
    }).catch(err => {
      dispatch(actions.sendForgotEmailFailure(err));
    });
  },
  sendForgotEmailSuccess: () => ({
    type: SEND_FORGOT_EMAIL_SUCCESS,
  }),
  sendForgotEmailFailure: (error) => ({
    type: SEND_FORGOT_EMAIL_FAILURE,
    error,
  }),

  requestResetPassword: () => ({ type: REQUEST_RESET_PASSWORD }),
  resetPassword: (password, token) => (dispatch) => {
    dispatch(actions.requestResetPassword());
    return fetch(RESET_PASSWORD_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({password, token}),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.info('Success', 'Your password has been reset!');
      dispatch(actions.resetPasswordSuccess());
    }).catch(err => {
      toastr.info('Token Expired', 'Please return to the login page and try again.');
      dispatch(actions.resetPasswordFailure(err));
    });
  },
  resetPasswordSuccess: () => ({
    type: RESET_PASSWORD_SUCCESS,
  }),
  resetPasswordFailure: (error) => ({
    type: RESET_PASSWORD_FAILURE,
    error,
  }),
};

export default actions;
