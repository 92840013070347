import { toastr } from 'react-redux-toastr';
import { trackStartTrial } from '../../tracking';
import {
  REQUEST_LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  REQUEST_LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  REQUEST_REGISTRATION,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  SET_ONLINE_STATUS,
  SET_LATEST_PATH,
} from './actionTypes';
import config from '../../config';
const API_BASE = config().API_BASE;
const LOGIN_USER_PATH = `${API_BASE}/auth/login`;
const LOGOUT_USER_PATH = `${API_BASE}/auth/logout`;
const REGISTER_USER_PATH = `${API_BASE}/auth/register`;


let actions = {
  requestLoginUser: () => ({ type: REQUEST_LOGIN_USER }),
  loginUser: (email, password) => (dispatch) => {
    dispatch(actions.requestLoginUser());
    return fetch(LOGIN_USER_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ email, password }),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.loginUserSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh', 'Your email or password is incorrect!');
      dispatch(actions.loginUserFailure(err));
    });
  },
  loginUserSuccess: (response) => ({
    type: LOGIN_USER_SUCCESS,
    response,
  }),
  loginUserFailure: (error) => ({
    type: LOGIN_USER_FAILURE,
    error,
  }),

  requestLogoutUser: () => ({ type: REQUEST_LOGOUT_USER }),
  logoutUser: (accessToken) => (dispatch) => {
    dispatch(actions.requestLogoutUser());
    return fetch(LOGOUT_USER_PATH, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.info('Logout', 'You have been logged out');
      dispatch(actions.logoutUserSuccess());
    }).catch(err => {
      dispatch(actions.logoutUserFailure(err));
    });
  },
  logoutUserSuccess: () => ({
    type: LOGOUT_USER_SUCCESS,
  }),
  logoutUserFailure: (error) => ({
    type: LOGOUT_USER_FAILURE,
    error,
  }),

  requestRegistration: () => ({ type: REQUEST_REGISTRATION }),
  registration: (name, email, password, inviteToken) => (dispatch) => {
    dispatch(actions.requestRegistration());
    return fetch(REGISTER_USER_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ name, email, password, inviteToken }),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      //toastr.success('Success!', 'Thanks for signing up!');
      trackStartTrial();
      dispatch(actions.registrationSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh', 'There was an error. Do you have an account already?');
      dispatch(actions.registrationFailure(err));
    });
  },
  registrationSuccess: (response) => ({
    type: REGISTRATION_SUCCESS,
    response,
  }),
  registrationFailure: (error) => ({
    type: REGISTRATION_FAILURE,
    error,
  }),

  setOnlineStatus: () => {
    return {
      type: SET_ONLINE_STATUS,
    }
  },
  setLatestPath: (path) => {
    return {
      type: SET_LATEST_PATH,
      path,
    }
  },
};

export default actions;
