import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';

import {
  REQUEST_GET_RECIPES,
  GET_RECIPES_SUCCESS,
  GET_RECIPES_FAILURE,
  REQUEST_GET_RECIPE,
  GET_RECIPE_SUCCESS,
  GET_RECIPE_FAILURE,
  REQUEST_CREATE_RECIPE,
  CREATE_RECIPE_SUCCESS,
  CREATE_RECIPE_FAILURE,
  REQUEST_DELETE_RECIPE,
  DELETE_RECIPE_SUCCESS,
  DELETE_RECIPE_FAILURE,
  REQUEST_UPDATE_RECIPE,
  UPDATE_RECIPE_SUCCESS,
  UPDATE_RECIPE_FAILURE,
  REQUEST_RECIPE_COUNT,
  RECIPE_COUNT_SUCCESS,
  RECIPE_COUNT_FAILURE,
} from './actionTypes';

import config from '../../../config'

const API_BASE = config().API_BASE;

let actions = {
  requestGetRecipes: () => ({ type: REQUEST_GET_RECIPES }),
  getRecipes: (accessToken, accountId, filter={}, ownerFirst) => (dispatch) => {
    dispatch(actions.requestGetRecipes());
    var query = queryString.stringify({filter: JSON.stringify(filter)});
    const ownerFirstPart = ownerFirst ? '/ownerFirst' : '';
    return fetch(`${API_BASE}/account/${accountId}/recipe${ownerFirstPart}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getRecipesSuccess(json));
    }).catch(err => {
      dispatch(actions.getRecipesFailure(err));
    });
  },
  getRecipesSuccess: (response) => ({
    type: GET_RECIPES_SUCCESS,
    response,
  }),
  getRecipesFailure: (error) => ({
    type: GET_RECIPES_FAILURE,
    error,
  }),

  requestRecipeCount: () => ({ type: REQUEST_RECIPE_COUNT }),
  recipeCount: (accessToken, accountId, where) => (dispatch) => {
    dispatch(actions.requestRecipeCount());
    var query = queryString.stringify({ filter: JSON.stringify({ where }) });
    return fetch(`${API_BASE}/account/${accountId}/recipe/count?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.recipeCountSuccess(json));
    }).catch(err => {
      dispatch(actions.recipeCountFailure(err));
    });
  },
  recipeCountSuccess: (response) => ({
    type: RECIPE_COUNT_SUCCESS,
    response,
  }),
  recipeCountFailure: (error) => ({
    type: RECIPE_COUNT_FAILURE,
    error,
  }),

  requestGetRecipe: () => ({ type: REQUEST_GET_RECIPE }),
  getRecipe: (accessToken, accountId, recipeId) => (dispatch) => {
    dispatch(actions.requestGetRecipe());
    let url, headers;
    if (accessToken) {
      const query = queryString.stringify({filter: JSON.stringify({
        where: { or: [{isBrewuiInstance: true}, {isPublic: true}] },
      })});
      url = `${API_BASE}/account/${accountId}/recipe/${recipeId}?${query}`;
      headers= {
        Authorization: `Bearer ${accessToken}`,
      };
    } else {
      url = `${API_BASE}/public/recipe/${recipeId}`;
      headers = {};
    }

    return fetch(url, { headers }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getRecipeSuccess(json));
    }).catch(err => {
      dispatch(actions.getRecipeFailure(err));
    });
  },
  getRecipeSuccess: (response) => ({
    type: GET_RECIPE_SUCCESS,
    response,
  }),
  getRecipeFailure: (error) => ({
    type: GET_RECIPE_FAILURE,
    error,
  }),

  requestCreateRecipe: () => ({ type: REQUEST_CREATE_RECIPE }),
  createRecipe: (accessToken, accountId, data) => (dispatch) => {
    dispatch(actions.requestCreateRecipe());
    return fetch(`${API_BASE}/account/${accountId}/recipe`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your recipe has been saved');
      dispatch(actions.createRecipeSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh', 'There was an error. Your recipe was not created.', { timeOut: 0 });
      dispatch(actions.createRecipeFailure(err));
    });
  },
  createRecipeSuccess: (response) => ({
    type: CREATE_RECIPE_SUCCESS,
    response,
  }),
  createRecipeFailure: (error) => ({
    type: CREATE_RECIPE_FAILURE,
    error,
  }),

  requestDeleteRecipe: () => ({ type: REQUEST_DELETE_RECIPE }),
  deleteRecipe: (accessToken, recipeId, accountId) => (dispatch) => {
    dispatch(actions.requestDeleteRecipe());
    return fetch(`${API_BASE}/account/${accountId}/recipe/${recipeId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.success('Success!', 'Your recipe has been deleted');
      dispatch(actions.deleteRecipeSuccess({id: recipeId}));
    }).catch(err => {
      dispatch(actions.deleteRecipeFailure(err));
    });
  },
  deleteRecipeSuccess: (response) => ({
    type: DELETE_RECIPE_SUCCESS,
    response,
  }),
  deleteRecipeFailure: (error) => ({
    type: DELETE_RECIPE_FAILURE,
    error,
  }),

  requestUpdateRecipe: () => ({ type: REQUEST_UPDATE_RECIPE }),
  updateRecipe: (accessToken, accountId, recipeId, data) => (dispatch) => {
    dispatch(actions.requestUpdateRecipe());
    return fetch(`${API_BASE}/account/${accountId}/recipe/${recipeId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your recipe has been saved');
      dispatch(actions.updateRecipeSuccess(json));
    }).catch(err => {
      dispatch(actions.updateRecipeFailure(err));
    });
  },
  updateRecipeSuccess: (response) => ({
    type: UPDATE_RECIPE_SUCCESS,
    response,
  }),
  updateRecipeFailure: (error) => ({
    type: UPDATE_RECIPE_FAILURE,
    error,
  }),
};

export default actions;
