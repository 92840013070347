import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';

import {
  REQUEST_GET_CATALOG,
  GET_CATALOG_SUCCESS,
  GET_CATALOG_FAILURE,
  REQUEST_GET_CATALOG_ITEM,
  GET_CATALOG_ITEM_SUCCESS,
  GET_CATALOG_ITEM_FAILURE,
  REQUEST_UPDATE_CATALOG_ITEM,
  UPDATE_CATALOG_ITEM_SUCCESS,
  UPDATE_CATALOG_ITEM_FAILURE,
  REQUEST_CREATE_CATALOG_ITEM,
  CREATE_CATALOG_ITEM_SUCCESS,
  CREATE_CATALOG_ITEM_FAILURE,
  REQUEST_DELETE_CATALOG_ITEM,
  DELETE_CATALOG_ITEM_SUCCESS,
  DELETE_CATALOG_ITEM_FAILURE,
  REQUEST_CATALOG_COUNT,
  CATALOG_COUNT_SUCCESS,
  CATALOG_COUNT_FAILURE,
  REQUEST_SEARCH_CATALOG,
  SEARCH_CATALOG_SUCCESS,
  SEARCH_CATALOG_FAILURE,

  REQUEST_IMPORT_CATALOG_ITEM,
  IMPORT_CATALOG_ITEM_SUCCESS,
  IMPORT_CATALOG_ITEM_FAILURE,
  REQUEST_EXPORT_CATALOG_ITEM,
  EXPORT_CATALOG_ITEM_SUCCESS,
  EXPORT_CATALOG_ITEM_FAILURE,
} from './actionTypes';

import config from '../../../config'

const { API_BASE } = config();

let actions = {
  requestGetCatalog: () => ({ type: REQUEST_GET_CATALOG }),
  getCatalog: (accessToken, accountId, model, filter={}, ownerFirst) => (dispatch) => {
    dispatch(actions.requestGetCatalog());
    var query = queryString.stringify({filter: JSON.stringify(filter)});
    const ownerFirstPart = ownerFirst ? '/ownerFirst' : '';
    return fetch(`${API_BASE}/account/${accountId}/${model}${ownerFirstPart}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getCatalogSuccess(json));
    }).catch(err => {
      dispatch(actions.getCatalogFailure(err));
    });
  },
  getCatalogSuccess: (response) => ({
    type: GET_CATALOG_SUCCESS,
    response,
  }),
  getCatalogFailure: (error) => ({
    type: GET_CATALOG_FAILURE,
    error,
  }),

  requestCatalogCount: () => ({ type: REQUEST_CATALOG_COUNT }),
  catalogCount: (accessToken, accountId, model, where) => (dispatch) => {
    dispatch(actions.requestCatalogCount());
    var query = queryString.stringify({filter: JSON.stringify({ where })});
    return fetch(`${API_BASE}/account/${accountId}/${model}/count?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.catalogCountSuccess(json));
    }).catch(err => {
      dispatch(actions.catalogCountFailure(err));
    });
  },
  catalogCountSuccess: (response) => ({
    type: CATALOG_COUNT_SUCCESS,
    response,
  }),
  catalogCountFailure: (error) => ({
    type: CATALOG_COUNT_FAILURE,
    error,
  }),

  requestGetCatalogItem: () => ({ type: REQUEST_GET_CATALOG_ITEM }),
  getCatalogItem: (accessToken, accountId, config, itemId, filter={}) => (dispatch) => {
    dispatch(actions.requestGetCatalogItem());
    var query = queryString.stringify({filter: JSON.stringify(filter)});
    return fetch(`${API_BASE}/account/${accountId}/${config.apiRelationPath}/${itemId}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getCatalogItemSuccess(json));
    }).catch(err => {
      dispatch(actions.getCatalogItemFailure(err));
    });
  },
  getCatalogItemSuccess: (response) => ({
    type: GET_CATALOG_ITEM_SUCCESS,
    response,
  }),
  getCatalogItemFailure: (error) => ({
    type: GET_CATALOG_ITEM_FAILURE,
    error,
  }),

  requestCreateCatalogItem: () => ({ type: REQUEST_CREATE_CATALOG_ITEM }),
  createCatalogItem: (accessToken, config, accountId, data) => (dispatch) => {
    dispatch(actions.requestCreateCatalogItem());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiRelationPath}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your item has been saved');
      dispatch(actions.createCatalogItemSuccess(json));
    }).catch(err => {
      dispatch(actions.createCatalogItemFailure(err));
    });
  },
  createCatalogItemSuccess: (response) => ({
    type: CREATE_CATALOG_ITEM_SUCCESS,
    response,
  }),
  createCatalogItemFailure: (error) => ({
    type: CREATE_CATALOG_ITEM_FAILURE,
    error,
  }),

  requestDeleteCatalogItem: () => ({ type: REQUEST_DELETE_CATALOG_ITEM }),
  deleteCatalogItem: (accessToken, config, itemId, accountId) => (dispatch) => {
    dispatch(actions.requestDeleteCatalogItem());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiBasePath}/${itemId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.success('Success!', 'Your item has been deleted');
      dispatch(actions.deleteCatalogItemSuccess({id: itemId}));
    }).catch(err => {
      dispatch(actions.deleteCatalogItemFailure(err));
    });
  },
  deleteCatalogItemSuccess: (response) => ({
    type: DELETE_CATALOG_ITEM_SUCCESS,
    response,
  }),
  deleteCatalogItemFailure: (error) => ({
    type: DELETE_CATALOG_ITEM_FAILURE,
    error,
  }),

  requestUpdateCatalogItem: () => ({ type: REQUEST_UPDATE_CATALOG_ITEM }),
  updateCatalogItem: (accessToken, config, itemId, data, accountId) => (dispatch) => {
    dispatch(actions.requestUpdateCatalogItem());
    return fetch(`${API_BASE}/account/${accountId}/${config.apiBasePath}/${itemId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your item has been saved');
      dispatch(actions.updateCatalogItemSuccess(json));
    }).catch(err => {
      dispatch(actions.updateCatalogItemFailure(err));
    });
  },
  updateCatalogItemSuccess: (response) => ({
    type: UPDATE_CATALOG_ITEM_SUCCESS,
    response,
  }),
  updateCatalogItemFailure: (error) => ({
    type: UPDATE_CATALOG_ITEM_FAILURE,
    error,
  }),

  requestSearchCatalog: () => ({ type: REQUEST_SEARCH_CATALOG }),
  searchCatalog: (accessToken, accountId, filter={}, models=[]) => (dispatch) => {
    dispatch(actions.requestSearchCatalog());
    var query = queryString.stringify({filter: JSON.stringify(filter), models: JSON.stringify(models)});
    return fetch(`${API_BASE}/account/${accountId}/searchIngredients?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.searchCatalogSuccess(json));
    }).catch(err => {
      dispatch(actions.searchCatalogFailure(err));
    });
  },
  searchCatalogSuccess: (response) => ({
    type: SEARCH_CATALOG_SUCCESS,
    response,
  }),
  searchCatalogFailure: (error) => ({
    type: SEARCH_CATALOG_FAILURE,
    error,
  }),

  requestImportCatalogItem: () => ({ type: REQUEST_IMPORT_CATALOG_ITEM }),
  importCatalogItem: ({ accessToken, accountId, files }) => (dispatch) => {
    dispatch(actions.requestImportCatalogItem());
    //upload and import files with 2 requests
    var formData = new FormData();
    for(let i=0; i<files.length; i++) {
      formData.append(`xmlFiles`, files[i]);
    }
    return fetch(`${API_BASE}/account/${accountId}/importXml`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your item has been imported');
      dispatch(actions.importCatalogItemSuccess(json));
    }).catch(err => {
      toastr.warning('Uh oh', 'Your item was not imported. Be sure it is an .xml file and less than 1mb in size.');
      dispatch(actions.importCatalogItemFailure(err));
    });
  },
  importCatalogItemSuccess: (response) => ({
    type: IMPORT_CATALOG_ITEM_SUCCESS,
    response,
  }),
  importCatalogItemFailure: (error) => ({
    type: IMPORT_CATALOG_ITEM_FAILURE,
    error,
  }),

  requestExportCatalogItem: () => ({ type: REQUEST_EXPORT_CATALOG_ITEM }),
  exportCatalogItem: ({ accessToken, accountId, type, filter }) => (dispatch) => {
    dispatch(actions.requestExportCatalogItem());
    const query = queryString.stringify({ filter: JSON.stringify(filter) });
    return fetch(`${API_BASE}/account/${accountId}/exportXml/${type}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json()
    }).then(json => {
      const key = json.key;
      const downloadURL = `${API_BASE}/account/${accountId}/downloadXml/${key}`
      window.location = downloadURL;
    }).catch(err => {
      toastr.warning('Uh oh', 'Your item was not exported.');
      dispatch(actions.exportCatalogItemFailure(err));
    });
  },
  exportCatalogItemSuccess: (response) => ({
    type: EXPORT_CATALOG_ITEM_SUCCESS,
    response,
  }),
  exportCatalogItemFailure: (error) => ({
    type: EXPORT_CATALOG_ITEM_FAILURE,
    error,
  }),
};

export default actions;
