import {
  REQUEST_GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  REQUEST_UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  REQUEST_CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  REQUEST_VERIFICATION_EMAIL,
  VERIFICATION_EMAIL_SUCCESS,
  VERIFICATION_EMAIL_FAILURE,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
} from './actionTypes';


const initialState = {
  isFetching: false,
  error: null,
  data: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_USER_SUCCESS:
      return Object.assign({}, initialState);
    case LOGOUT_USER_FAILURE:
      return Object.assign({}, initialState);

    case REQUEST_GET_CUSTOMER:
      return Object.assign({}, state, { isFetching: true, error: null, });
    case GET_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        data: action.response,
        isFetching: false,
       });
    case GET_CUSTOMER_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_UPDATE_CUSTOMER:
      return Object.assign({}, state, { isFetching: true });
    case UPDATE_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        data: action.response,
        isFetching: false,
      });
    case UPDATE_CUSTOMER_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
      });

    case REQUEST_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_VERIFICATION_EMAIL:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case VERIFICATION_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case VERIFICATION_EMAIL_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default reducer;
