import {
  REQUEST_GET_SESSIONS,
  GET_SESSIONS_SUCCESS,
  GET_SESSIONS_FAILURE,
  REQUEST_GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILURE,
  REQUEST_CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAILURE,
  REQUEST_DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAILURE,
  REQUEST_UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILURE,
  REQUEST_ATTACH_RECIPE,
  ATTACH_RECIPE_SUCCESS,
  ATTACH_RECIPE_FAILURE,
  REQUEST_ACTIVE_SESSIONS,
  ACTIVE_SESSIONS_SUCCESS,
  ACTIVE_SESSIONS_FAILURE,
  REQUEST_CREATE_READING,
  CREATE_READING_SUCCESS,
  CREATE_READING_FAILURE,
  REQUEST_DELETE_READING,
  DELETE_READING_SUCCESS,
  DELETE_READING_FAILURE,
  REMOVE_SESSION_DATA,
} from './actionTypes';

import {
  REQUEST_GET_RECIPE,
  GET_RECIPE_SUCCESS,
  GET_RECIPE_FAILURE,
  REQUEST_CREATE_RECIPE,
  CREATE_RECIPE_SUCCESS,
  CREATE_RECIPE_FAILURE,
  REQUEST_UPDATE_RECIPE,
  UPDATE_RECIPE_SUCCESS,
  UPDATE_RECIPE_FAILURE,
} from '../Library/Recipes/actionTypes';
const initialState = {
  isFetching: false,
  data: [],
  selectedSession: null,
  selectedRecipe: null,
  savedSession: null,
  activeSessions: [],
  isDeleted: false,
  isReadingCreated: false,
  isReadingDeleted: false,
  error: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_SESSION_DATA:
      let currState = Object.assign({}, state);
      action.keys.forEach((k) => {
        currState[k] = initialState[k];
      });
      return currState;

    case REQUEST_GET_SESSIONS:
      return Object.assign({}, state, { isFetching: true });
    case GET_SESSIONS_SUCCESS:
      return Object.assign({}, state, {
        data: action.response,
        isFetching: false,
      });
    case GET_SESSIONS_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_ACTIVE_SESSIONS:
      return Object.assign({}, state, { isFetching: true });
    case ACTIVE_SESSIONS_SUCCESS:
      return Object.assign({}, state, {
        activeSessions: action.response,
        isFetching: false,
      });
    case ACTIVE_SESSIONS_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_SESSION:
      return Object.assign({}, state, { isFetching: true });
    case GET_SESSION_SUCCESS:
      return Object.assign({}, state, {
        selectedSession: action.response,
        isFetching: false,
      });
    case GET_SESSION_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_RECIPE:
      return Object.assign({}, state, { isFetching: true });
    case GET_RECIPE_SUCCESS:
      return Object.assign({}, state, {
        selectedRecipe: action.response,
        isFetching: false,
      });
    case GET_RECIPE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_UPDATE_RECIPE:
      return Object.assign({}, state, { isFetching: true });
    case UPDATE_RECIPE_SUCCESS:
      return Object.assign({}, state, {
        selectedRecipe: action.response,
        isFetching: false,
      });
    case UPDATE_RECIPE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_CREATE_RECIPE:
      return Object.assign({}, state, { isFetching: true });
    case CREATE_RECIPE_SUCCESS:
      return Object.assign({}, state, {
        selectedSession: Object.assign({}, state.selectedSession, { recipeId: action.response.id }),
        isFetching: false,
      });
    case CREATE_RECIPE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_CREATE_SESSION:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CREATE_SESSION_SUCCESS:
      return Object.assign({}, state, {
        data: [action.response, ...state.data],
        savedSession: action.response,
        selectedRecipe: null,
        isFetching: false,
      });
    case CREATE_SESSION_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_DELETE_SESSION:
      return Object.assign({}, state, { isDeleted: false, isFetching: true });
    case DELETE_SESSION_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.filter(session => session.id !== action.response.id),
        isFetching: false,
        selectedSession: null,
        isDeleted: true,
      });
    case DELETE_SESSION_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
        isDeleted: false,
      });

    case REQUEST_UPDATE_SESSION:
      return Object.assign({}, state, { isFetching: true });
    case UPDATE_SESSION_SUCCESS:
      const index = state.data.map(d => d.id).indexOf(action.response.id);
      const data = [...state.data];
      const updatedSession = Object.assign({}, state.selectedSession, action.response);
      data[index] = updatedSession;
      return Object.assign({}, state, {
        selectedSession: updatedSession,
        savedSession: updatedSession,
        selectedRecipe: action.response.recipeId ? state.selectedRecipe : null,
        data,
        isFetching: false,
      });
    case UPDATE_SESSION_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
      });

    case REQUEST_ATTACH_RECIPE:
      return Object.assign({}, state, { isFetching: true });
    case ATTACH_RECIPE_SUCCESS:
      const sIndex = state.data.map(d => d.id).indexOf(action.response.id);
      let sessData = [...state.data];
      sessData[sIndex] = action.response;
      return Object.assign({}, state, {
        selectedSession: action.response,
        savedSession: action.response,
        data: sessData,
        isFetching: false,
      });
    case ATTACH_RECIPE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
      });

    case REQUEST_CREATE_READING:
      return Object.assign({}, state, {
        isFetching: true,
        isReadingCreated: false,
      });
    case CREATE_READING_SUCCESS:
      return Object.assign({}, state, {
        isReadingCreated: true,
        isFetching: false,
      });
    case CREATE_READING_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
        isReadingCreated: false,
      });

    case REQUEST_DELETE_READING:
      return Object.assign({}, state, {
        isFetching: true,
        isReadingDeleted: false,
      });
    case DELETE_READING_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isReadingDeleted: true,
      });
    case DELETE_READING_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
        isReadingDeleted: false,
      });

    default:
      return state;
  }
}

export default reducer;
