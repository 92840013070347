import {
  REQUEST_GET_STYLES,
  GET_STYLES_SUCCESS,
  GET_STYLES_FAILURE,
} from './actionTypes';
import queryString from 'query-string';
import config from '../../../config'

const API_BASE = config().API_BASE;

let actions = {
  requestGetStyles: () => ({ type: REQUEST_GET_STYLES }),
  getStyles: (accessToken, accountId, filter={}) => (dispatch) => {
    dispatch(actions.requestGetStyles());
    var query = queryString.stringify({filter: JSON.stringify({ where: { or: [{isBrewuiInstance: true}] }, limit: 500 })});
    return fetch(`${API_BASE}/account/${accountId}/style?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
        return resp.json();
    }).then(json => {
      if (json.error) {
        throw new Error(json.error.code);
      }
      dispatch(actions.getStylesSuccess(json));
    }).catch(err => {
      dispatch(actions.getStylesFailure(err));
    });
  },
  getStylesSuccess: (response) => ({
    type: GET_STYLES_SUCCESS,
    response,
  }),
  getStylesFailure: (error) => ({
    type: GET_STYLES_FAILURE,
    error,
  }),
};

export default actions;
