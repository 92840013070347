import React, { Component } from 'react';
import ReduxToastr from 'react-redux-toastr';
import { Router, Route, Switch } from 'react-router-dom';
import 'react-redux-toastr/src/styles/index.scss';
import './App.scss';
import { createBrowserHistory } from 'history';
import Loadable from './views/Global/Loadable';
import { Offline, Online } from "react-detect-offline";

// Containers
// Pages
const LoginContainer = Loadable(() => import('./views/Auth/components/LoginContainer'));
const LogoutContainer = Loadable(() => import('./views/Auth/components/Logout'));
const RegisterContainer = Loadable(() => import('./views/Auth/components/RegisterContainer'));
const ForgotPassword = Loadable(() => import('./views/ForgotPassword/ForgotPassword'));
const ResetPassword = Loadable(() => import('./views/ForgotPassword/ResetPassword'));
const DefaultLayoutContainer = Loadable(() => import('./views/DefaultLayout/DefaultLayoutContainer'), { noFallback: true });
const Page404 = Loadable(() => import('./views/Page404/Page404'));
const Page500 = Loadable(() => import('./views/Page500/Page500'));
const VerifyEmail = Loadable(() => import('./views/VerifyEmail/VerifyEmail'));
const ServiceWorkerWrapper = Loadable(() => import('./ServiceWorkerWrapper'), { noFallback: true });
const PublicRecipeContainer = Loadable(() => import('./views/Library/Recipes/components/PublicRecipeContainer'));

const history = createBrowserHistory();

class App extends Component {
  reload = () => {
    window.location.reload();
  }
  render() {
    return (
      <>
        <Online>
          <ServiceWorkerWrapper />
          <ReduxToastr />
          <Router history={history}>
            <Switch>
              <Route path="/public/recipes/:recipeId" name="Recipe" component={PublicRecipeContainer} />
              <Route exact path="/login" name="Login Page" component={LoginContainer} />
              <Route exact path="/logout" name="Logout Page" component={LogoutContainer} />
              <Route exact path="/register" name="Register Page" component={RegisterContainer} />
              <Route exact path="/forgot_password" name="Forgot Password" component={ForgotPassword} />
              <Route exact path="/reset_password" name="Reset Password" component={ResetPassword} />
              <Route exact path="/verify_email" name="Verify Email" component={VerifyEmail} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route exact path="/" name="Register Page" component={RegisterContainer} />
              <Route path="/" name="Home" component={DefaultLayoutContainer} />
              <Route path="*" name="404" component={Page404} />
            </Switch>
          </Router>
        </Online>
        <Offline>
          <div className="no_wifi_screen d-flex flex-column justify-content-center">
            <h1><i class="fas fa-sad-tear"></i></h1>
            <p>No internet connection! Make sure Wi-Fi is on, airplane mode is off, and try again.</p>
            <p><button onClick={this.reload} className="btn btn-primary">Retry</button></p>
          </div>
        </Offline>
      </>
    );
  }
}

export default App;
