import { toastr } from 'react-redux-toastr';
import {
  REQUEST_GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  REQUEST_UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  REQUEST_CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  REQUEST_VERIFICATION_EMAIL,
  VERIFICATION_EMAIL_SUCCESS,
  VERIFICATION_EMAIL_FAILURE,
} from './actionTypes';
import config from '../../config'

const API_BASE = config().API_BASE;

let actions = {
  requestGetCustomer: () => ({ type: REQUEST_GET_CUSTOMER }),
  getCustomer: (accountId, id, accessToken) => (dispatch) => {
    dispatch(actions.requestGetCustomer());
    return fetch(`${API_BASE}/account/${accountId}/customer/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getCustomerSuccess(json));
    }).catch(err => {
      dispatch(actions.getCustomerFailure(err));
    });
  },
  getCustomerSuccess: (response) => ({
    type: GET_CUSTOMER_SUCCESS,
    response,
  }),
  getCustomerFailure: (error) => ({
    type: GET_CUSTOMER_FAILURE,
    error,
  }),

  requestUpdateCustomer: () => ({ type: REQUEST_UPDATE_CUSTOMER }),
  updateCustomer: (accessToken, customerId, data, silent, accountId) => (dispatch) => {
    dispatch(actions.requestUpdateCustomer());
    return fetch(`${API_BASE}/account/${accountId}/customer/${customerId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      if (!silent) toastr.success('Success!', 'Your changes have been saved');
      dispatch(actions.updateCustomerSuccess(json));
    }).catch(err => {
      if (!silent) toastr.warning('Uh oh!', 'There was an error');
      dispatch(actions.updateCustomerFailure(err));
    });
  },
  updateCustomerSuccess: (response) => ({
    type: UPDATE_CUSTOMER_SUCCESS,
    response,
  }),
  updateCustomerFailure: (error) => ({
    type: UPDATE_CUSTOMER_FAILURE,
    error,
  }),

  //account data actions
  requestChangePassword: () => ({ type: REQUEST_CHANGE_PASSWORD }),
  changePassword: (accessToken, data, accountId) => (dispatch) => {
    dispatch(actions.requestChangePassword());
    return fetch(`${API_BASE}/account/${accountId}/customer/updatePassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your password has been saved');
      dispatch(actions.changePasswordSuccess());
    }).catch(err => {
      toastr.warning('Uh oh!', 'You password was not changed. Please check your current password.');
      dispatch(actions.changePasswordFailure(err));
    });
  },
  changePasswordSuccess: (response) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    response,
  }),
  changePasswordFailure: (error) => ({
    type: CHANGE_PASSWORD_FAILURE,
    error,
  }),

  //account data actions
  requestVerificationEmail: () => ({ type: REQUEST_VERIFICATION_EMAIL }),
  verificationEmail: (accessToken, customerId, accountId) => (dispatch) => {
    dispatch(actions.requestVerificationEmail());
    return fetch(`${API_BASE}/account/${accountId}/customer/${customerId}/sendVerificationEmail`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      toastr.success('Success!', 'A new verification email has been sent');
      dispatch(actions.verificationEmailSuccess());
    }).catch(err => {
      toastr.warning('Uh oh!', 'There was an error');
      dispatch(actions.verificationEmailFailure(err));
    });
  },
  verificationEmailSuccess: (response) => ({
    type: VERIFICATION_EMAIL_SUCCESS,
    response,
  }),
  verificationEmailFailure: (error) => ({
    type: VERIFICATION_EMAIL_FAILURE,
    error,
  }),
};

export default actions;
