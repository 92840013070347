import {
  REQUEST_GET_NOTES,
  GET_NOTES_SUCCESS,
  GET_NOTES_FAILURE,
  REQUEST_GET_NOTE,
  GET_NOTE_SUCCESS,
  GET_NOTE_FAILURE,
  REQUEST_CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  REQUEST_DELETE_NOTE,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILURE,
  REQUEST_UPDATE_NOTE,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAILURE,
  REQUEST_NOTE_COUNT,
  NOTE_COUNT_SUCCESS,
  NOTE_COUNT_FAILURE,
} from './actionTypes';

const initialState = {
  isFetching: false,
  data: [],
  selectedNote: null,
  count: null,
  error: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {

    case REQUEST_GET_NOTES:
      return Object.assign({}, state, { isFetching: true });
    case GET_NOTES_SUCCESS:
      return Object.assign({}, state, {
        data: action.response,
        isFetching: false,
        //count: null,
       });
    case GET_NOTES_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_NOTE_COUNT:
      return Object.assign({}, state, { isFetching: true });
    case NOTE_COUNT_SUCCESS:
      return Object.assign({}, state, {
        count: action.response.count,
        isFetching: false,
       });
    case NOTE_COUNT_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_NOTE:
      return Object.assign({}, state, { isFetching: true });
    case GET_NOTE_SUCCESS:
      return Object.assign({}, state, {
        selectedNote: action.response,
        isFetching: false,
       });
    case GET_NOTE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_CREATE_NOTE:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CREATE_NOTE_SUCCESS:
      return Object.assign({}, state, {
        data: [...state.data, action.response],
        isFetching: false,
       });
    case CREATE_NOTE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_DELETE_NOTE:
      return Object.assign({}, state, { isFetching: true });
    case DELETE_NOTE_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.filter(note => note.id !== parseInt(action.response.id)),
        isFetching: false,
        selectedNote: null,
      });
    case DELETE_NOTE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_UPDATE_NOTE:
      return Object.assign({}, state, { isFetching: true });
    case UPDATE_NOTE_SUCCESS:
      const index = state.data.map(d => d.id).indexOf(action.response.id);
      let data = [...state.data];
      data[index] = action.response;
      return Object.assign({}, state, {
        selectedNote: action.response,
        data,
        isFetching: false,
      });
    case UPDATE_NOTE_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
      });

    default:
      return state;
  }
}

export default reducer;
