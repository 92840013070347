import React from 'react'

import loadable from '@loadable/component';

function Loading(props) {
  return <div className="m-3"><i className="fas fa-spinner fa-pulse"></i> Loading page...</div>;
}

export default function Loadable(loader, options={}) {
  const loaderOpts = {};
  if (!options.noFallback) {
    loaderOpts.fallback = <Loading />;
  }
  return loadable(loader, loaderOpts);
}
