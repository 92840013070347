import {
  REQUEST_GET_CATALOG,
  GET_CATALOG_SUCCESS,
  GET_CATALOG_FAILURE,
  REQUEST_GET_CATALOG_ITEM,
  GET_CATALOG_ITEM_SUCCESS,
  GET_CATALOG_ITEM_FAILURE,
  REQUEST_UPDATE_CATALOG_ITEM,
  UPDATE_CATALOG_ITEM_SUCCESS,
  UPDATE_CATALOG_ITEM_FAILURE,
  REQUEST_CREATE_CATALOG_ITEM,
  CREATE_CATALOG_ITEM_SUCCESS,
  CREATE_CATALOG_ITEM_FAILURE,
  REQUEST_DELETE_CATALOG_ITEM,
  DELETE_CATALOG_ITEM_SUCCESS,
  DELETE_CATALOG_ITEM_FAILURE,
  REQUEST_CATALOG_COUNT,
  CATALOG_COUNT_SUCCESS,
  CATALOG_COUNT_FAILURE,
  REQUEST_SEARCH_CATALOG,
  SEARCH_CATALOG_SUCCESS,
  SEARCH_CATALOG_FAILURE,

  REQUEST_IMPORT_CATALOG_ITEM,
  IMPORT_CATALOG_ITEM_SUCCESS,
  IMPORT_CATALOG_ITEM_FAILURE,
  REQUEST_EXPORT_CATALOG_ITEM,
  EXPORT_CATALOG_ITEM_SUCCESS,
  EXPORT_CATALOG_ITEM_FAILURE,
} from './actionTypes';

const initialState = {
  isFetching: false,
  data: [],
  selectedItem: null,
  searchResults: [],
  isImporting: false,
  isExporting: false,
  isSaved: false,
  count: null,
  error: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {

    case REQUEST_GET_CATALOG:
      return Object.assign({}, state, { isFetching: true, data: [] });
    case GET_CATALOG_SUCCESS:
      return Object.assign({}, state, {
        data: action.response,
        isFetching: false,
        //count: null,
       });
    case GET_CATALOG_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_CATALOG_COUNT:
      return Object.assign({}, state, { isFetching: true });
    case CATALOG_COUNT_SUCCESS:
      return Object.assign({}, state, {
        count: action.response.count,
        isFetching: false,
       });
    case CATALOG_COUNT_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_CATALOG_ITEM:
      return Object.assign({}, state, { isFetching: true });
    case GET_CATALOG_ITEM_SUCCESS:
      return Object.assign({}, state, {
        selectedItem: action.response,
        isFetching: false,
       });
    case GET_CATALOG_ITEM_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_CREATE_CATALOG_ITEM:
      return Object.assign({}, state, {
        isFetching: true,
        isSaved: false,
      });
    case CREATE_CATALOG_ITEM_SUCCESS:
      return Object.assign({}, state, {
        data: [action.response, ...state.data],
        isFetching: false,
        isSaved: true,
       });
    case CREATE_CATALOG_ITEM_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
        isSaved: false,
      });

    case REQUEST_DELETE_CATALOG_ITEM:
      return Object.assign({}, state, { isFetching: true });
    case DELETE_CATALOG_ITEM_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.filter(it => it.id !== parseInt(action.response.id)),
        isFetching: false,
        selectedItem: null,
      });
    case DELETE_CATALOG_ITEM_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_UPDATE_CATALOG_ITEM:
      return Object.assign({}, state, { isFetching: true, isSaved: false });
    case UPDATE_CATALOG_ITEM_SUCCESS:
      const index = state.data.map(d => d.id).indexOf(action.response.id);
      let data = [...state.data];
      data[index] = action.response;
      return Object.assign({}, state, {
        selectedItem: action.response,
        data,
        isFetching: false,
        isSaved: true,
      });
    case UPDATE_CATALOG_ITEM_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
        isSaved: false,
      });

    case REQUEST_SEARCH_CATALOG:
      return Object.assign({}, state, { isFetching: true });
    case SEARCH_CATALOG_SUCCESS:
      return Object.assign({}, state, {
        searchResults: action.response,
        isFetching: false,
      });
    case SEARCH_CATALOG_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_IMPORT_CATALOG_ITEM:
      return Object.assign({}, state, { isImporting: true, });
    case IMPORT_CATALOG_ITEM_SUCCESS:
      return Object.assign({}, state, { isImporting: false, });
    case IMPORT_CATALOG_ITEM_FAILURE:
      return Object.assign({}, state, { isImporting: false, });

    case REQUEST_EXPORT_CATALOG_ITEM:
      return Object.assign({}, state, { isExporting: true, });
    case EXPORT_CATALOG_ITEM_SUCCESS:
      return Object.assign({}, state, { isExporting: false, });
    case EXPORT_CATALOG_ITEM_FAILURE:
      return Object.assign({}, state, { isExporting: false, });
    default:
      return state;
  }
}

export default reducer;
