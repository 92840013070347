
//fermentables
exports.potentialSG = function(fermentableYield) {
  return (((fermentableYield/100) * 0.046) + 1).toFixed(3);
}

//water
exports.alkalinity = function(bicarbonate) {
  return 0.959182778*bicarbonate*50/61;
}

exports.effectiveHardness = function(ca, mg) {
  return (ca/1.4) + (mg/1.7);
}

exports.residualAlkalinity = function(bicarb, ca, mg) {
  return exports.alkalinity(bicarb) - exports.effectiveHardness(ca, mg);
}

exports.lowRecommendedSRM = function(bicarb, ca, mg) {
  return exports.residualAlkalinity(bicarb, ca, mg)*0.082 + 5.2;
}

exports.highRecommendedSRM = function(bicarb, ca, mg) {
  return (exports.residualAlkalinity(bicarb, ca, mg) + 122.4) / 12.2;
}

exports.sulfateChlorideRatio = function(so4, ci) {
  const ratio = so4/ci;
  let profile = 'Out of Range'
  if (ratio >= 0 && ratio <= 0.4) {
    profile = 'Extremely Malty';
  } else if (ratio > 0.4 && ratio <= 0.6) {
    profile = 'Very Malty';
  } else if (ratio > 0.6 && ratio <= 0.8) {
    profile = 'Malty';
  } else if (ratio > 0.8 && ratio <= 1.5) {
    profile = 'Balanced';
  } else if (ratio > 1.5 && ratio <= 2) {
    profile = 'Slightly Bitter';
  } else if (ratio > 2 && ratio <= 4) {
    profile = 'Bitter';
  } else if (ratio > 4 && ratio <= 9) {
    profile = 'Very Bitter';
  } else if (ratio > 9 && ratio <= 18) {
    profile = 'Extremely Bitter';
  }
  return [ratio.toFixed(2), profile];
}

exports.waterCombinedMineralContent = (waters) => {
  let totalWater = waters.reduce((acc, w) => acc + w.form.amount, 0);
  let weights = waters.map(w => w.form.amount / totalWater);
  const minerals = {
    calcium: 0,
    magnesium: 0,
    alkalinity: 0,
    bicarb: 0,
    sulfate: 0,
    chloride: 0,
    sodium: 0,
  }
  waters.forEach((w, i) => {
    minerals.calcium += w.item.calcium * weights[i];
    minerals.magnesium += w.item.magnesium * weights[i];
    minerals.alkalinity += w.item.alkalinity * weights[i];
    minerals.bicarb += w.item.bicarb * weights[i];
    minerals.sulfate += w.item.sulfate * weights[i];
    minerals.chloride += w.item.chloride * weights[i];
    minerals.sodium += w.item.sodium * weights[i];
  });
  return minerals;
}

exports.getMashStepVolumes = function({
  boilingTemp = 212,
  ratio = 1.25,
  stepTemps = [],
  initalTemp = 70,
  grainWeight,
}) {
  if (!grainWeight || !stepTemps.length) throw 'Missing arguments';
  const stepVolumes = [{
    infusionTemp: exports.infusionTemperature({ratio, targetTemp: stepTemps[0], mashTemp: initalTemp}),
    volume: 1.25*grainWeight,
  }];

  for (var i=1; i<stepTemps.length; i++) {
    stepVolumes.push({
      infusionTemp: boilingTemp,
      volume: exports.volumeForInfusionStep(stepTemps[i-1], stepTemps[i], grainWeight, stepVolumes[i-1]['volume'], boilingTemp),
    });
  }
  return stepVolumes;
}

exports.tunTempAdjustment = function({ temperature, tunWeight, tunSpecificHeat }) {
  //this matches brewsmiths temp adjustment
  return (3.926*(tunWeight*tunSpecificHeat))+temperature;
}

exports.infusionTemperature = function({ ratio, targetTemp, mashTemp, }) {
  //normal ratio in quarts/lbs is 1.5
  if (!ratio || !targetTemp || !mashTemp) return;
  return (.2 / ratio) * (parseInt(targetTemp) - parseInt(mashTemp)) + parseInt(targetTemp);
}

exports.infusionTemperatureWithVol = function({
  targetTemp,
  mashTemp,
  grainWeight,
  initialVol,
  infusionVol
}) {
  //volume: quarts, weight: pounds, returns volume: temp
  if (!targetTemp || !mashTemp || !grainWeight || typeof initialVol === 'undefined' || !infusionVol) return;
  return ((targetTemp - mashTemp) * (0.2 * grainWeight + initialVol) + (infusionVol * targetTemp)) / infusionVol;
}

exports.volumeForInfusionStep = function(initalTemp, targetTemp, grainWeight, initalWaterVolume, infusionWaterTemp) {
  //volume: quarts, weight: pounds, returns volume: quarts
  if (!initalTemp || !targetTemp || !grainWeight || !initalWaterVolume || !infusionWaterTemp) return;
  const boilingWaterNeeded = (targetTemp - initalTemp) * (0.2 * grainWeight + initalWaterVolume) / (infusionWaterTemp - targetTemp);
  return boilingWaterNeeded;
}

exports.biabTotalWater = function(grain, grainAbsorption, boilOff, boilTime, batchSize, trub) {
  if (!grain || !grainAbsorption || !boilOff || !boilTime || !batchSize || !trub) return;
  var result = (parseFloat(grain) * parseFloat(grainAbsorption)) + (parseFloat(boilOff) * (parseFloat(boilTime) / 60)) + parseFloat(batchSize) + parseFloat(trub);
  return result;
}

exports.biabWaterTemp = function(totalWater, grain, mashTemp, grainTemp) {
  // (.2/((totalwater/grain)*4))*(mashTemp-grainTemp)+mashTemp
  if (!totalWater || !grain || !mashTemp || !grainTemp) return;
  var result = (.2/((parseFloat(totalWater)/parseFloat(grain))*4))*(parseFloat(mashTemp)-parseFloat(grainTemp))+parseFloat(mashTemp);
  return result;
}

exports.biabTotalMashVolume = function(totalWater, grain) {
  if (!totalWater || !grain) return;
  var grainSpace = .08;
  return (grain * grainSpace) + totalWater;
}

exports.biabPreBoilWortVolume = function(totalWater, grain, grainAbsorption) {
  if (!totalWater || !grain || !grainAbsorption) return;
  return totalWater - (grain * grainAbsorption);
}

exports.biabPostBoilWortVolume = function(preBoilWortVolume, boilOff, boilTime) {
  if (!preBoilWortVolume || !boilOff || !boilTime) return;
  return preBoilWortVolume - (boilOff * (boilTime / 60))
}

exports.biabFermenterVolume = function(postBoilWortVolume, trub) {
  if (!postBoilWortVolume || !trub) return;
  return postBoilWortVolume - trub;
}

exports.abv = function(abw, fg) {
  return abw * (fg / 0.794);
}

exports.abw = function(og, fg) {
  return 76.08 * (og - fg) / (1.775 - og);
}

exports.ogFromHydrometerRefractometer = function(sg, brix) {
  var GRAV = sg;
  var BRIX = brix;
  //var h = (277.8851 - 277.4 * GRAV + 0.9956 * BRIX + 0.00523 * BRIX + 0.000013 * BRIX) * (GRAV / 0.79);
  //var i = Math.round(h * 1000) / 1000;
  var j = (100 * (194.5935 + 129.8 * GRAV + (1.33302 + 0.001427193 * BRIX + 0.000005791157 * (BRIX ^ 2)) * (410.8815 * (1.33302 + 0.001427193 * BRIX + 0.000005791157 * (BRIX ^ 2)) - 790.8732) + 2.0665 * (1017.5596 - 277.4 * GRAV + (1.33302 + 0.001427193 * BRIX + 0.000005791157 * (BRIX ^ 2)) * (937.8135 * (1.33302 + 0.001427193 * BRIX + 0.000005791157 * (BRIX ^ 2)) - 1805.1228)))) / (100 + 1.0665 * (1017.5596 - 277.4 * GRAV + (1.33302 + 0.001427193 * BRIX + 0.000005791157 * (BRIX ^ 2)) * (937.8135 * (1.33302 + 0.001427193 * BRIX + 0.000005791157 * (BRIX ^ 2)) - 1805.1228)));
  var k = j / (258.6 - (j / 258.2) * 227.1) + 1;
  var og = Math.round(k * 1000) / 1000;
  return og;
}

exports.hydrometerAdjustment = function(measuredGravity, temperature, calibrationTemp) {
  return measuredGravity * ((1.00130346 - 0.000134722124 * temperature + 0.00000204052596 * Math.pow(temperature, 2) - 0.00000000232820948 * Math.pow(temperature, 3)) / (1.00130346 - 0.000134722124 * calibrationTemp + 0.00000204052596 * Math.pow(calibrationTemp, 2) - 0.00000000232820948 * Math.pow(calibrationTemp, 3)));
}

exports.specificGravityToBrix = function(sg) {
  return (((182.4601 * sg -775.6821) * sg +1262.7794) * sg -669.5622);
}

exports.platoToSpecificGravity = function(plato) {
  return 1+plato/(258.6-(227.1*(plato/258.2)));
}

exports.brixToSpecificGravity = function(brix) {
  return (brix / (258.6-((brix / 258.2)*227.1))) + 1;
}

exports.correctedBrix = function(brix, correction) {
  return brix/correction;
}

exports.refractometerBrixToOriginalGravity = function(brix, correction) {
  const correctedBrix = brix/correction;
  return (correctedBrix / (258.6-((correctedBrix / 258.2)*227.1))) + 1;
}

exports.refractometerBrixToSpecificGravity = function(fgBrix, ogBrix) {
  return 1 + 0.006276 * fgBrix - 0.002349 * ogBrix;
}

exports.refractiveIndexToBrix = function(num) {
  return -9829.317136+18636.22371*num-11884.63962*Math.pow(num,2)+2577.474135*Math.pow(num,3);
}

exports.brixToRefractiveIndex = function(num) {
  return 1.3330229+0.00142117428*num+0.0000056370904*Math.pow(num,2)+0.0000000154588009*Math.pow(num,3);
}

exports.brixCorrectionFactor = function(distilledWaterReading, refractometerReading, hydrometerReading) {
  const refReading = refractometerReading - distilledWaterReading;
  return refReading/((-1*616.868)+(1111.14*hydrometerReading)-(630.272*Math.pow(hydrometerReading,2))+(135.997*Math.pow(hydrometerReading,3)));
}

exports.targetGravityDilution = function(postBoilVolume, postBoilGravity, desiredGravity) {
  var newVolume = ((postBoilGravity - 1) / (desiredGravity - 1)) * postBoilVolume;
  return newVolume - postBoilVolume;
}

exports.gravityAfterDilution = function(startingVolume, startingGravity, additionVolume, additionGravity) {
	const sGrav = startingGravity - 1;
  const aGrav = additionGravity - 1;
  const finalVolume = startingVolume + additionVolume;
  const percAdded = startingVolume / finalVolume;

  if (!additionGravity) {
    return sGrav * percAdded + 1;
  } else {
  	const gravDiff = sGrav - aGrav;
    const effectGrav = (1 - percAdded) * gravDiff;
    return (sGrav - effectGrav) + 1;
  }
}

exports.dmeGravityAdjust = function(originalGravity, targetGravity, currentVolume) {
  //vol: gal, returns: lbs
  if (!originalGravity || !targetGravity || !currentVolume) return;
  return (((targetGravity - 1) * 1000) - ((originalGravity - 1) * 1000)) / 44 * currentVolume;
}

exports.lmeGravityAdjust = function(originalGravity, targetGravity, currentVolume) {
  //vol: gal, returns ?
  if (!originalGravity || !targetGravity || !currentVolume) return;
  return (((targetGravity - 1) * 1000) - ((originalGravity - 1) * 1000)) / 37 * currentVolume;
}

exports.gravityPostBoil = function(boilGravity, boilVolume, postBoilVolume) {
  var postBoilGravity = (boilGravity - 1) * (boilVolume / postBoilVolume);
  postBoilGravity = postBoilGravity + 1;
  return postBoilGravity;
}

exports.hopElevationUtilization = (elevation) => {
  return 1 / (((elevation / 550) * 0.02) + 1);
}

exports.garetzIBU = function(startingGravity, boilVolume, finalVolume, desiredIbus, elevation, hops=[]) {
  const validHops = [];
  hops.forEach(h => {
    if (h.mass && h.alpha && h.time) {
      validHops.push(h);
    }
  });
  if (!startingGravity || !boilVolume || !finalVolume || !desiredIbus || !elevation || validHops.length === 0) {
    return;
  }
  function garetzUtil(time) {
    if (time >= 0 && time <= 5) {
      return 0;
    } else if (time >= 6 && time <= 10) {
      return 0;
    } else if (time >= 11 && time <= 15) {
      return 2;
    } else if (time >= 16 && time <= 20) {
      return 5;
    } else if (time >= 21 && time <= 25) {
      return 8;
    } else if (time >= 26 && time <= 30) {
      return 11;
    } else if (time >= 31 && time <= 35) {
      return 14;
    } else if (time >= 36 && time <= 40) {
      return 16;
    } else if (time >= 41 && time <= 45) {
      return 18;
    } else if (time >= 46 && time <= 50) {
      return 19;
    } else if (time >= 51 && time <= 60) {
      return 20;
    } else if (time >= 61 && time <= 70) {
      return 21;
    } else if (time >= 71 && time <= 80) {
      return 22;
    } else if (time >= 81 && time <= 90) {
      return 23;
    }
  }
  const concentrationFactor = finalVolume/boilVolume;
  const boilGravity = (concentrationFactor * (startingGravity - 1)) + 1;
  const gravityFactor = (boilGravity - 1.05) / 0.2 + 1;
  const hoppingRate = ((concentrationFactor * desiredIbus) / 260) + 1;
  const tempFactor = ((elevation / 550) * 0.02) + 1;
  const combinedAdj = gravityFactor * hoppingRate * tempFactor;


  let bu = 0;
  for (var i=0; i<validHops.length; i++){
    const hop = validHops[i];
    const util = garetzUtil(hop.time);
    const ibu = (util * (hop.alpha*100) * hop.mass * 0.749) / (boilVolume * combinedAdj);
    bu += (ibu*hop.adjustment)+ibu;
  }
  return bu;
}

exports.ragerIBU = function(boilGravity, boilVolume, hops, elevation=0) {
  const elevationUtil = exports.hopElevationUtilization(elevation);
  const validHops = [];
  hops.forEach(h => {
    if (h.mass && h.alpha && h.time) {
      validHops.push(h);
    }
  });
  if (!boilGravity || !boilVolume || validHops.length === 0) {
    return;
  }
  let ga = 0;
  if (boilGravity > 1.05) {
    ga = (boilGravity - 1.05) / 0.2;
  }
  let bu = 0;
  for (var i=0; i<validHops.length; i++){
    const hop = validHops[i];
    let util = 18.11 + 13.86 * Math.tanh((hop.time - 31.32) / 18.27);
    util *= elevationUtil;
    const ibu = (hop.mass * (util/100) * hop.alpha * 7490) / (boilVolume * (1 + ga));
    bu += (ibu*hop.adjustment)+ibu;
  }
  return bu;
}

exports.tinsethIBU = function(boilGravity, boilVolume, hops, elevation=0) {
  const elevationUtil = exports.hopElevationUtilization(elevation);
  const validHops = [];
  hops.forEach(h => {
    if (h.mass && h.alpha && h.time) {
      validHops.push(h);
    }
  });
  if (!boilGravity || !boilVolume || validHops.length === 0) {
    return;
  }
  let bu = 0;
  const gravityOffset = boilGravity-1;
  for (var i=0; i<validHops.length; i++){
    const hop = validHops[i];
    const mgperl = hop.alpha*hop.mass*7490/boilVolume;
    let util = 1.65*Math.pow(0.000125, gravityOffset)*(1-Math.exp(-0.04*hop.time))/4.15;
    util *= elevationUtil;
    const ibu = mgperl*util;
    bu += (ibu*hop.adjustment)+ibu;
  }
  return bu;
}

exports.getGrainBillPotentialPoints = function(fermentables) {
  const points = fermentables.reduce((acc, curr) => {
    const pts = exports.potentialSG(curr.yield) * 1000 - 1000;
    return acc + (pts * curr.lb);
  }, 0);
  return points;
}

exports.getEfficiency = function({ fermentables, gravity, volume }) {
  //volume = gal
  const potentialPoints = exports.getGrainBillPotentialPoints(fermentables);
  const actualPoints = gravity * 1000 - 1000;
  return actualPoints / (potentialPoints / volume);
}

exports.getGravity = function(fermentables, efficiency, volume) {
  //vol = gal
  const potentialPoints = exports.getGrainBillPotentialPoints(fermentables);
  points = potentialPoints * efficiency;
  const og = ((points / volume) + 1000) / 1000;
  return og;
}

exports.attenuationData = function({ originalGravity, finalGravity }) {
  if (!originalGravity || !finalGravity) {
    throw 'Missing arguments'
  }

  const originalExtract = exports.specificGravityToBrix(originalGravity);
  const apparentExtract = exports.specificGravityToBrix(finalGravity);
  const realExtract = (0.1808 * originalExtract) + (0.8192 * apparentExtract);
  const apparentAttenuation = exports.attenuation(originalGravity, finalGravity);
  const realAttenuation = 100 * (originalExtract - realExtract)/originalExtract;

  return {
    originalExtract,
    apparentExtract,
    realExtract,
    apparentAttenuation,
    realAttenuation,
  };
}

exports.finalGravity = function(og, attenuation) {
  //https://www.northernbrewer.com/blogs/extract-brewing/final-gravity
  return og - ((og-1) * attenuation);
}

exports.attenuation = function(og, fg) {
  const originalExtract = exports.specificGravityToBrix(og);
  const apparentExtract = exports.specificGravityToBrix(fg);
  return ((originalExtract - apparentExtract) / originalExtract) * 100;
}

exports.attenuationAdjustment = function(settings, mashSteps) {
  const filteredSteps = mashSteps.filter(st => st.stepTemp >= 145 && st.stepTemp <= 158);
  const centerTemp = settings.centerMashTempFgAdj;
  const slope = settings.slopeFgAttenAdj;
  const numSteps = filteredSteps.length;
  const totalMins = filteredSteps.reduce((acc, st) => acc + st.stepTime, 0);
  const weightedTempAverage = filteredSteps.reduce((acc, st) => {
    const perc = st.stepTime / totalMins;
    return acc + (st.stepTemp * perc);
  }, 0);
  const tempDiff = weightedTempAverage - centerTemp;
  return slope * tempDiff;
}

exports.caloriesFromAbv = function(og, fg) {
  return 1881.22 * fg * (og-fg)/(1.775-og);
}

exports.caloriesFromCarbs = function(og, fg) {
  return 3550.0 * fg * ((0.1808 * og) + (0.8192 * fg) - 1.0004);
}

exports.colorEstimate = function(grains, volume) {
  //http://brewwiki.com/index.php/Estimating_Color
  let totalMcu = grains.reduce((acc, curr) => {
    const mcu = (curr.srm * curr.lb) / volume;
    return acc + mcu;
  }, 0);

  const srm = 1.4922 * Math.pow(totalMcu, 0.6859);
  return srm;
}

exports.convertColor = function(val, toSrm) {
  if (!toSrm) {
    //to ebc
    return val * 1.97;
  } else {
    //to srm
    return val * 0.508;
  }
}

exports.getLiquidExtractVolume = function({ weight }) {
  //weight oz, returns floz
  return weight * 0.714;
}

exports.getVolumeEstimates = function({
  batchSize,
  grainWeight,
  boilTime,
  equipment,
  grainAbsorption,
  settings,
  mash,
  type,
  steepGrainWeight = 0,
  extractVolume = 0,
}) {
  //everything is in fl-oz or oz
  if (!batchSize || typeof grainWeight !== 'number'  || !boilTime || !equipment || !grainAbsorption || !settings || !type) {
    throw 'Missing arguments'
  }
  const isExtract = type === 'Extract';
  const isBIAB = mash && mash.biab;
  const topUp = equipment.topUp;
  const trubLoss = equipment.trubLoss;
  const shrinkage = 1-(equipment.coolPct/100);
  const shrinkageVol = ((batchSize + trubLoss) / shrinkage) - (batchSize + trubLoss);
  const boilOff = equipment.boilOff;
  const deadspace = equipment.tunDeadspace || 0;
  const tunAddition = equipment.tunAddition || 0;
  const shouldAdjForDeadspace = equipment.tunAdjDeadspace;
  const grainAbsorptionVolume = grainWeight * grainAbsorption;
  const boilOffVolume = boilOff * (boilTime/60);

  let totalMashVolume = 0;
  if (mash && mash.steps && mash.steps.length > 0) {
    const adjustMashWithDeadspace = equipment.tunAdjDeadspace;
    if (adjustMashWithDeadspace) {
      totalMashVolume += equipment.tunDeadspace || 0;
    }
    mash.steps.forEach(st => {
        if(st.type === 'Infusion') {
          totalMashVolume += grainWeight * st.ratio;
          //console.log(st.name, grainWeight * st.ratio / 128)
        }
    });
  }

  let wortFromMashVolume = totalMashVolume - grainAbsorptionVolume - deadspace;


  /*
  bottling volume = batch vol - fermentation loss
  batch vol = batch vol
  pre top up volume = batch vol - topUp
  post boil volume = pre top up volume + trub loss + shrinkage
  pre boil volume = post boil volume + boil off volume
  total volume = pre boil volume + grain absorption volume + tun additions + [tun deadspace]
  */

  //these are the volume steps
  const bottlingVolume = batchSize - equipment.fermenterLoss;

  const preTopUpBatch = batchSize - topUp;

  const postBoilVolume = (preTopUpBatch + trubLoss) / shrinkage;

  const preBoilVolume = postBoilVolume + boilOffVolume;

  let spargeVolume =  preBoilVolume - wortFromMashVolume;
  //wort from mash above
  //total wort water
  const evaporationRate = boilOffVolume / preBoilVolume;

  let totalVolume = preBoilVolume + grainAbsorptionVolume + tunAddition + deadspace;

  //biab exceptions
  if (isBIAB) {
    totalVolume = preBoilVolume + grainAbsorptionVolume;
    wortFromMashVolume = totalVolume - grainAbsorptionVolume + deadspace;
    spargeVolume = 0;
    totalMashVolume = totalVolume;
  }

  //extract exceptions
  if (isExtract) {
    totalVolume = preBoilVolume;
  }


  if (shouldAdjForDeadspace && !isExtract && !isBIAB) {
    //totalVolume += deadspace;
  }

  const requiredTunVolume = totalMashVolume + (grainWeight * settings.grainVolume);
  /*
  console.log({
    bottlingVolume: bottlingVolume/128,
    batchSize: batchSize/128,
    preTopUpBatch: preTopUpBatch/128,
    postBoilVolume: postBoilVolume/128,
    preBoilVolume: preBoilVolume/128,
    spargeVolume: spargeVolume/128,
    totalVolume: totalVolume/128,
    tunAddition: tunAddition/128,
    trubLoss: trubLoss/128,
    shrinkage: shrinkageVol/128,
    shrinkageRate: (shrinkageVol / postBoilVolume) * 100,
    grainAbsorption: grainAbsorptionVolume/128,
    boilOffVolume: boilOffVolume/128,
    evaporationRate,
    grainWeight: grainWeight/16,
    totalMashVolume: totalMashVolume/128,
    wortFromMashVolume: wortFromMash/128,
  })
  */
  return {
    bottlingVolume,
    batchSize,
    preTopUpBatch,
    postBoilVolume,
    preBoilVolume,
    spargeVolume,
    totalVolume,
    tunAddition,
    trubLoss,
    shrinkage: shrinkageVol,
    shrinkageRate: (shrinkageVol / postBoilVolume) * 100,
    grainAbsorption: grainAbsorptionVolume.toFixed(2),
    boilOffVolume,
    evaporationRate,
    grainWeight,
    requiredTunVolume,
    totalMashVolume,
    wortFromMashVolume,
  }
}

exports.boilOffCalculator = function(startingVolume, startingGravity, evaporationRate, boilTime, shrinkageRate) {
  const evaporationPerHour = startingVolume * evaporationRate;
  const tev = evaporationPerHour * (boilTime / 60);
  const postBoilVolume = startingVolume - tev;
  const finalVolume = postBoilVolume - (postBoilVolume * shrinkageRate);
  const volDiff = startingVolume / finalVolume;
  const finalGravity = ((startingGravity - 1) * volDiff) + 1;
  return {
    evaporationPerHour,
    boilOffVolume: startingVolume - postBoilVolume,
    coolingShrinkage: postBoilVolume - finalVolume,
    finalVolume,
    finalGravity,
  };
}

exports.co2PressureNeeded = function({ temp, volumes }) {
  //fahrenheit, returns psi
  const pressure = -16.6999 - (0.0101059 * temp) + (0.00116512 * temp * temp) + (0.173354 * temp * volumes) + (4.24267 * volumes) - (0.0684226 * volumes * volumes);
  return pressure;
}

exports.primingSugarNeeded = function({ temp, volumes, batchVolume }) {
  //fahrenheit, liters, returns g
  const beerCO2 = 3.0378 - (0.050062 * temp) + (0.00026555 * temp * temp);
	const sucrose = ((volumes * 2) - (beerCO2 * 2)) * 2 * batchVolume;
  return {
    'Table Sugar': sucrose,
    'Corn Sugar': sucrose / 0.91,
    'Dry Malt Extract': sucrose / 0.68,
    dmeLaaglander: sucrose / 0.5,
    turbinado: sucrose,
    demarara: sucrose,
    cornsyrup: sucrose / 0.69,
    mrownSugar: sucrose / 0.89,
    molasses: sucrose / 0.71,
    mapleSyrup: sucrose / 0.77,
    sorghumSyrup: sucrose / 0.69,
    'Honey': sucrose / 0.74,
    belgianCandySyrup: sucrose / 0.63,
    belgianCandySugar: sucrose / 0.75,
    invertSugar: sucrose / 0.91,
    blackTreacle: sucrose / 0.87,
    riceSolids: sucrose / 0.79,
    weight_unit: "g",
  }
}

exports.getBicarbonate = ({ totalAlkalinity, ph }) => {
  return ((totalAlkalinity/50)/(1+(2*10^(ph-10.33))))*61;
}

exports.waterChemistry = function({
  mashVol,
  grainWeight,
  spargeVol = 0,
  calcium = 0,
  magnesium = 0,
  sodium = 0,
  chlorine = 0,
  sulfate = 0,
  calciumCarbonate = 0,

  gypsum = 0,
  calciumChloride = 0,
  epsomSalt = 0,
  chalk = 0,
  bakingSoda = 0,
  slakedLime = 0,

  laticAcid = 0,
  laticAcidPerc = 0.88,
  acidulatedMalt = 0,
  acidulatedMaltPerc = 0.02,

}) {
  if (!mashVol || typeof grainWeight === 'undefined') {
    throw 'Missing arguments';
  }
  /*
    grainBill = [
      {weight, srm}
    ]
    totalWeight = 0
    tally = 0
    for (gb in grainBill)
      gMashPh = 5.22-0.00504*gb.srm
      tally += gb.weight * gMashPh
      totalWeight += gb.weight

    phWGrain = tall/totalWeight;

  */

  const distilledSpargePerc = 0;
  const distilledMashPerc = 0;
  const phWGrain = 5.72;

  const measuredCalcium = ((1-distilledMashPerc)*calcium+(chalk*105.89+gypsum*60+calciumChloride*72+slakedLime*143)/mashVol).toFixed(0);
  const measuredMagnesium = ((1-distilledMashPerc)*magnesium+epsomSalt*24.6/mashVol).toFixed(0);
  const measuredSodium = ((1-distilledMashPerc)*sodium+bakingSoda*72.3/mashVol).toFixed(0);
  const measuredChloride = ((1-distilledMashPerc)*chlorine+calciumChloride*127.47/mashVol).toFixed(0);
  const measuredSulfate = ((1-distilledMashPerc)*sulfate+(gypsum*147.4+epsomSalt*103)/mashVol).toFixed(0);
  const measuredClorideSulfateRatio = (measuredChloride/measuredSulfate).toFixed(2);
  const effectiveAlkalinity = ((1-distilledMashPerc)*calciumCarbonate*(50/61)+(chalk*130*bakingSoda*157-176.1*laticAcid*laticAcidPerc*2-4160.4*acidulatedMaltPerc*acidulatedMalt*2.5+slakedLime*357)/mashVol).toFixed(0);
  const residualAlkalinity = (effectiveAlkalinity - ((measuredCalcium/1.4)+(measuredMagnesium/1.7))).toFixed(0);
  let mashPh;
  if (grainWeight > 0) {
    mashPh = (phWGrain+(0.1085*mashVol/grainWeight+0.013)*residualAlkalinity/50).toFixed(2);
  }
  return {
    effectiveAlkalinity,
    residualAlkalinity,
    mashPh,
    measuredCalcium,
    measuredMagnesium,
    measuredSodium,
    measuredChloride,
    measuredSulfate,
    measuredClorideSulfateRatio,
  };
}

exports.yeastRequired = ({
  pitchRate,
  batchVolume, // ml
  wortPlato, // plato
}) => {
  const yeastNeeded = (pitchRate * batchVolume * wortPlato) / 1000;
  return yeastNeeded;
}

exports.starterDme = ({ starterVolume, starterGravity }) => {
  //vol = gal, gravity = sg return oz
  const pointsNeeded = starterVolume * ((starterGravity - 1) * 1000);
  const ouncesDme = (pointsNeeded / 42) * 16;
  return ouncesDme;
}

exports.cellCountWithStir = ({ dme, yeastCount }) => {
  //dme = gram, gravity = billion return billion
  var cellsDmeRatio = yeastCount / dme;
  let growthRate = 0;
  if (cellsDmeRatio < 1.4) {
		growthRate = 1.4
	} else if (cellsDmeRatio >= 1.4 && cellsDmeRatio <= 3.5) {
		growthRate = 2.33 - (0.67 * cellsDmeRatio);
		if (growthRate < 0) {
			growthRate = 0
		}
	}
  const cellCount = (dme * growthRate) + yeastCount;
  return cellCount;
}

exports.cellCount = ({ starterVolume, yeastCount, shaking }) => {
  //vol = l
  var inoculationRate = yeastCount / starterVolume;
  let growth = (12.54793776 * Math.pow(inoculationRate, -0.4594858324)) - 0.9994994906;
  if (inoculationRate >= 200) {
		growth = 0;
	}
	if (inoculationRate <= 5) {
		growth = 6;
	}
	if (growth > 6) {
		growth = 6;
	}
	if (growth < 0) {
		growth = 0;
	}
  if (shaking && growth <= 5.5) {
    growth += 0.5
  }
  endingCount = (1 + growth) * yeastCount;
  return endingCount;
}

exports.getPitchRate = ({ yeastCount, wortPlato, wortVolume }) => {
  //count/billions wort/plato volume/ml return M cells / mL / &deg;P
  return ((yeastCount * 1000) / wortPlato) / wortVolume;
}
