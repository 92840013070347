import {
  //list
  REQUEST_GET_LISTS,
  GET_LISTS_SUCCESS,
  GET_LISTS_FAILURE,

  REQUEST_GET_LISTS_FOR_ACTIONS,
  GET_LISTS_FOR_ACTIONS_SUCCESS,
  GET_LISTS_FOR_ACTIONS_FAILURE,

  REQUEST_GET_LIST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILURE,

  REQUEST_CREATE_LIST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAILURE,

  REQUEST_DELETE_LIST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_FAILURE,

  REQUEST_UPDATE_LIST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_FAILURE,

  //list items
  REQUEST_GET_LIST_ITEMS,
  GET_LIST_ITEMS_SUCCESS,
  GET_LIST_ITEMS_FAILURE,

  REQUEST_GET_LIST_ITEM,
  GET_LIST_ITEM_SUCCESS,
  GET_LIST_ITEM_FAILURE,

  REMOVE_LIST_ITEM,

  REQUEST_CREATE_LIST_ITEM,
  CREATE_LIST_ITEM_SUCCESS,
  CREATE_LIST_ITEM_FAILURE,

  REQUEST_DELETE_LIST_ITEM,
  DELETE_LIST_ITEM_SUCCESS,
  DELETE_LIST_ITEM_FAILURE,

  REQUEST_UPDATE_LIST_ITEM,
  UPDATE_LIST_ITEM_SUCCESS,
  UPDATE_LIST_ITEM_FAILURE,

  SELECT_LIST_SUCCESS,
  SELECT_LIST_ITEM_SUCCESS,
  SET_LIST_DATA,
} from './actionTypes';
import { LIST_MODELS } from 'brewui-common/constants';

function formatter(items) {
  return items.map(res => {
    const _itemType = LIST_MODELS.find(m => res[m]);
    let _item = null;
    let _itemsInInventory = 0;
    if (_itemType) {
      _item = res[_itemType];
      const inventoryItems = _item['inventoryItems'] || [];
      _itemsInInventory = inventoryItems
        .map(it => it.amount)
        .reduce((accum, curr) => {
          return accum + curr;
        }, _itemsInInventory);
    }
    return Object.assign({}, res, {
      _itemType,
      _item,
      _itemsInInventory,
    })
  });
}

const initialState = {
  isFetching: false,
  lists: [],
  listsForActions: [],
  listItems: [],
  selectedList: null,
  selectedListItem: null,
  error: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {

    //lists
    case REQUEST_GET_LISTS:
      return Object.assign({}, state, { isFetching: true });
    case GET_LISTS_SUCCESS:
      return Object.assign({}, state, {
        lists: action.response,
        isFetching: false,
       });
    case GET_LISTS_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_LISTS_FOR_ACTIONS:
      return Object.assign({}, state, { isFetching: true });
    case GET_LISTS_FOR_ACTIONS_SUCCESS:
      return Object.assign({}, state, {
        listsForActions: action.response,
        isFetching: false,
       });
    case GET_LISTS_FOR_ACTIONS_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_LIST:
      return Object.assign({}, state, { isFetching: true });
    case GET_LIST_SUCCESS:
      return Object.assign({}, state, {
        selectedList: action.response,
        isFetching: false,
       });
    case GET_LIST_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_CREATE_LIST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CREATE_LIST_SUCCESS:
      return Object.assign({}, state, {
        lists: [...state.lists, action.response],
        isFetching: false,
       });
    case CREATE_LIST_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_DELETE_LIST:
      return Object.assign({}, state, { isFetching: true });
    case DELETE_LIST_SUCCESS:
      return Object.assign({}, state, {
        lists: state.lists.filter(note => note.id !== action.response.id),
        isFetching: false,
        selectedList: null,
      });
    case DELETE_LIST_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_UPDATE_LIST:
      return Object.assign({}, state, { isFetching: true });
    case UPDATE_LIST_SUCCESS:
      const index = state.lists.map(d => d.id).indexOf(action.response.id);
      let lists = [...state.lists];
      lists[index] = action.response;
      return Object.assign({}, state, {
        selectedList: action.response.id,
        lists,
        isFetching: false,
      });
    case UPDATE_LIST_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    //items
    case REQUEST_GET_LIST_ITEMS:
      return Object.assign({}, state, { isFetching: true, listItems: [] });
    case GET_LIST_ITEMS_SUCCESS:
      const resp = formatter(action.response);
      return Object.assign({}, state, {
        listItems: resp,
        isFetching: false,
       });
    case GET_LIST_ITEMS_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_GET_LIST_ITEM:
      return Object.assign({}, state, { isFetching: true });
    case GET_LIST_ITEM_SUCCESS:
      return Object.assign({}, state, {
        selectedListItem: action.response,
        isFetching: false,
       });
    case GET_LIST_ITEM_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });
    case REMOVE_LIST_ITEM:
      return Object.assign({}, state, {
        selectedListItem: null,
      });
    case REQUEST_CREATE_LIST_ITEM:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CREATE_LIST_ITEM_SUCCESS:
      return Object.assign({}, state, {
        listItems: [...state.listItems, action.response],
        isFetching: false,
       });
    case CREATE_LIST_ITEM_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_DELETE_LIST_ITEM:
      return Object.assign({}, state, { isFetching: true });
    case DELETE_LIST_ITEM_SUCCESS:
      return Object.assign({}, state, {
        listItems: state.listItems.filter(d => d.id !== action.response.id),
        isFetching: false,
        selectedListItem: null,
      });
    case DELETE_LIST_ITEM_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_UPDATE_LIST_ITEM:
      return Object.assign({}, state, { isFetching: true });
    case UPDATE_LIST_ITEM_SUCCESS:
      const ind = state.listItems.map(d => d.id).indexOf(action.response.id);
      let listItems = [...state.listItems];
      const newListItem = Object.assign({}, listItems[ind], action.response);
      listItems[ind] = formatter([newListItem])[0];
      return Object.assign({}, state, {
        selectedListItem: listItems[ind],
        listItems,
        isFetching: false,
      });
    case UPDATE_LIST_ITEM_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case SELECT_LIST_SUCCESS:
      return Object.assign({}, state, {
        selectedList: parseInt(action.response.id),
      });
    case SELECT_LIST_ITEM_SUCCESS:
      return Object.assign({}, state, {
        selectedList: parseInt(action.response.id),
      });
    case SET_LIST_DATA:
      let currState = Object.assign({}, state, action.listData);
      return currState;
    default:
      return state;
  }
}

export default reducer;
