export const REQUEST_LOGIN_USER = 'REQUEST_LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const REQUEST_LOGOUT_USER = 'REQUEST_LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const REQUEST_REGISTRATION = 'REQUEST_REGISTRATION';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';

export const SET_LATEST_PATH = 'SET_LATEST_PATH';
