import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';

import {
  REQUEST_GET_NOTES,
  GET_NOTES_SUCCESS,
  GET_NOTES_FAILURE,
  REQUEST_GET_NOTE,
  GET_NOTE_SUCCESS,
  GET_NOTE_FAILURE,
  REQUEST_CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  REQUEST_DELETE_NOTE,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILURE,
  REQUEST_UPDATE_NOTE,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAILURE,
  REQUEST_NOTE_COUNT,
  NOTE_COUNT_SUCCESS,
  NOTE_COUNT_FAILURE,
} from './actionTypes';

import config from '../../../config'

const API_BASE = config().API_BASE;

let actions = {
  requestGetNotes: () => ({ type: REQUEST_GET_NOTES }),
  getNotes: (accessToken, filter={}, accountId) => (dispatch) => {
    dispatch(actions.requestGetNotes());
    var query = queryString.stringify({filter: JSON.stringify(filter)});
    return fetch(`${API_BASE}/account/${accountId}/note?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getNotesSuccess(json));
    }).catch(err => {
      dispatch(actions.getNotesFailure(err));
    });
  },
  getNotesSuccess: (response) => ({
    type: GET_NOTES_SUCCESS,
    response,
  }),
  getNotesFailure: (error) => ({
    type: GET_NOTES_FAILURE,
    error,
  }),

  requestNoteCount: () => ({ type: REQUEST_NOTE_COUNT }),
  noteCount: (accessToken, where, accountId) => (dispatch) => {
    dispatch(actions.requestNoteCount());
    var query = queryString.stringify({where: JSON.stringify(where)});
    return fetch(`${API_BASE}/account/${accountId}/note/count?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.noteCountSuccess(json));
    }).catch(err => {
      dispatch(actions.noteCountFailure(err));
    });
  },
  noteCountSuccess: (response) => ({
    type: NOTE_COUNT_SUCCESS,
    response,
  }),
  noteCountFailure: (error) => ({
    type: NOTE_COUNT_FAILURE,
    error,
  }),

  requestGetNote: () => ({ type: REQUEST_GET_NOTE }),
  getNote: (accessToken, noteId, notes, accountId) => (dispatch) => {
    dispatch(actions.requestGetNote());
    const note = notes.find(n => n.id === parseInt(noteId));

    if (note) {
      //return dispatch(actions.getNoteSuccess(note))
    }
    return fetch(`${API_BASE}/account/${accountId}/note/${noteId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.getNoteSuccess(json));
    }).catch(err => {
      dispatch(actions.getNoteFailure(err));
    });
  },
  getNoteSuccess: (response) => ({
    type: GET_NOTE_SUCCESS,
    response,
  }),
  getNoteFailure: (error) => ({
    type: GET_NOTE_FAILURE,
    error,
  }),

  requestCreateNote: () => ({ type: REQUEST_CREATE_NOTE }),
  createNote: (accessToken, data, accountId) => (dispatch) => {
    dispatch(actions.requestCreateNote());
    return fetch(`${API_BASE}/account/${accountId}/note`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      toastr.success('Success!', 'Your note has been saved');
      dispatch(actions.createNoteSuccess(json));
    }).catch(err => {
      dispatch(actions.createNoteFailure(err));
    });
  },
  createNoteSuccess: (response) => ({
    type: CREATE_NOTE_SUCCESS,
    response,
  }),
  createNoteFailure: (error) => ({
    type: CREATE_NOTE_FAILURE,
    error,
  }),

  requestDeleteNote: () => ({ type: REQUEST_DELETE_NOTE }),
  deleteNote: (accessToken, noteId, accountId) => (dispatch) => {
    dispatch(actions.requestDeleteNote());
    return fetch(`${API_BASE}/account/${accountId}/note/${noteId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      dispatch(actions.deleteNoteSuccess({id: noteId}));
    }).catch(err => {
      dispatch(actions.deleteNoteFailure(err));
    });
  },
  deleteNoteSuccess: (response) => ({
    type: DELETE_NOTE_SUCCESS,
    response,
  }),
  deleteNoteFailure: (error) => ({
    type: DELETE_NOTE_FAILURE,
    error,
  }),

  requestUpdateNote: () => ({ type: REQUEST_UPDATE_NOTE }),
  updateNote: (accessToken, noteId, data, accountId) => (dispatch) => {
    dispatch(actions.requestUpdateNote());
    return fetch(`${API_BASE}/account/${accountId}/note/${noteId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.status >= 400) throw new Error(resp.status);
      return resp.json();
    }).then(json => {
      dispatch(actions.updateNoteSuccess(json));
    }).catch(err => {
      dispatch(actions.updateNoteFailure(err));
    });
  },
  updateNoteSuccess: (response) => ({
    type: UPDATE_NOTE_SUCCESS,
    response,
  }),
  updateNoteFailure: (error) => ({
    type: UPDATE_NOTE_FAILURE,
    error,
  }),

};

export default actions;
