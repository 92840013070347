//GET /{catalog name}
export const REQUEST_GET_CATALOG = 'REQUEST_GET_CATALOG';
export const GET_CATALOG_SUCCESS = 'GET_CATALOG_SUCCESS';
export const GET_CATALOG_FAILURE = 'GET_CATALOG_FAILURE';

//GET /{catalog name}/{id}
export const REQUEST_GET_CATALOG_ITEM = 'REQUEST_GET_CATALOG_ITEM';
export const GET_CATALOG_ITEM_SUCCESS = 'GET_CATALOG_ITEM_SUCCESS';
export const GET_CATALOG_ITEM_FAILURE = 'GET_CATALOG_ITEM_FAILURE';

//PATCH /{catalog name}/{id}
export const REQUEST_UPDATE_CATALOG_ITEM = 'REQUEST_UPDATE_CATALOG_ITEM';
export const UPDATE_CATALOG_ITEM_SUCCESS = 'UPDATE_CATALOG_ITEM_SUCCESS';
export const UPDATE_CATALOG_ITEM_FAILURE = 'UPDATE_CATALOG_ITEM_FAILURE';

//POST /{catalog name}
export const REQUEST_CREATE_CATALOG_ITEM = 'REQUEST_CREATE_CATALOG_ITEM';
export const CREATE_CATALOG_ITEM_SUCCESS = 'CREATE_CATALOG_ITEM_SUCCESS';
export const CREATE_CATALOG_ITEM_FAILURE = 'CREATE_CATALOG_ITEM_FAILURE';

//DELETE /{catalog name}/{id}
export const REQUEST_DELETE_CATALOG_ITEM = 'REQUEST_DELETE_CATALOG_ITEM';
export const DELETE_CATALOG_ITEM_SUCCESS = 'DELETE_CATALOG_ITEM_SUCCESS';
export const DELETE_CATALOG_ITEM_FAILURE = 'DELETE_CATALOG_ITEM_FAILURE';

//GET /Account/{id}/{catalog name}/count
export const REQUEST_CATALOG_COUNT = 'REQUEST_CATALOG_COUNT';
export const CATALOG_COUNT_SUCCESS = 'CATALOG_COUNT_SUCCESS';
export const CATALOG_COUNT_FAILURE = 'CATALOG_COUNT_FAILURE';

//GET /catalog/search
export const REQUEST_SEARCH_CATALOG = 'REQUEST_SEARCH_CATALOG';
export const SEARCH_CATALOG_SUCCESS = 'SEARCH_CATALOG_SUCCESS';
export const SEARCH_CATALOG_FAILURE = 'SEARCH_CATALOG_FAILURE';

//GET /Container/...
export const REQUEST_UPLOAD_CATALOG_ITEM = 'REQUEST_UPLOAD_CATALOG_ITEM';
export const UPLOAD_CATALOG_ITEM_SUCCESS = 'UPLOAD_CATALOG_ITEM_SUCCESS';
export const UPLOAD_CATALOG_ITEM_FAILURE = 'UPLOAD_CATALOG_ITEM_FAILURE';

//GET /Container/...
export const REQUEST_IMPORT_CATALOG_ITEM = 'REQUEST_IMPORT_CATALOG_ITEM';
export const IMPORT_CATALOG_ITEM_SUCCESS = 'IMPORT_CATALOG_ITEM_SUCCESS';
export const IMPORT_CATALOG_ITEM_FAILURE = 'IMPORT_CATALOG_ITEM_FAILURE';

//GET /Container/...
export const REQUEST_EXPORT_CATALOG_ITEM = 'REQUEST_EXPORT_CATALOG_ITEM';
export const EXPORT_CATALOG_ITEM_SUCCESS = 'EXPORT_CATALOG_ITEM_SUCCESS';
export const EXPORT_CATALOG_ITEM_FAILURE = 'EXPORT_CATALOG_ITEM_FAILURE';
