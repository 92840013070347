import {
  REQUEST_GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  REQUEST_UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
} from './actionTypes';


const initialState = {
  isFetching: false,
  error: null,
  data: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_USER_SUCCESS:
      return Object.assign({}, initialState);
    case LOGOUT_USER_FAILURE:
      return Object.assign({}, initialState);

    case REQUEST_GET_SETTINGS:
      return Object.assign({}, state, { isFetching: true, error: null, });
    case GET_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        data: action.response[0] || null,
        isFetching: false,
       });
    case GET_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
        isFetching: false,
      });

    case REQUEST_UPDATE_SETTINGS:
      return Object.assign({}, state, { isFetching: true });
    case UPDATE_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        data: action.response,
        isFetching: false,
      });
    case UPDATE_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        error: action.error.message,
      });

    default:
      return state;
  }
}

export default reducer;
