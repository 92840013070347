//GET /Notes
export const REQUEST_GET_NOTES = 'REQUEST_GET_NOTES';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_FAILURE = 'GET_NOTES_FAILURE';

//GET /Notes/{id}
export const REQUEST_GET_NOTE = 'REQUEST_GET_NOTE';
export const GET_NOTE_SUCCESS = 'GET_NOTE_SUCCESS';
export const GET_NOTE_FAILURE = 'GET_NOTE_FAILURE';

//POST /Notes
export const REQUEST_CREATE_NOTE = 'REQUEST_CREATE_NOTE';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_FAILURE = 'CREATE_NOTE_FAILURE';

//DELETE /Notes/{id}
export const REQUEST_DELETE_NOTE = 'REQUEST_DELETE_NOTE';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAILURE = 'DELETE_NOTE_FAILURE';

//PATCH /Notes/{id}
export const REQUEST_UPDATE_NOTE = 'REQUEST_UPDATE_NOTE';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE';

//GET /Account/{id}/notes/count
export const REQUEST_NOTE_COUNT = 'REQUEST_NOTE_COUNT';
export const NOTE_COUNT_SUCCESS = 'NOTE_COUNT_SUCCESS';
export const NOTE_COUNT_FAILURE = 'NOTE_COUNT_FAILURE';
